import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { IoPersonSharp } from "react-icons/io5";
import { Navbar, Nav, NavDropdown, Badge } from 'react-bootstrap';
import { SignalCellularNullOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { userLogoutAsyncThunk } from "../redux/pagesSlices/authSlice";
import axios from "axios";
import { toast } from "react-toastify";

const Header = () => {
  let user = JSON.parse(localStorage.getItem("user")) || null;
  console.log("🚀 ~ HomePage ~ user:", user);
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const logoutHandler = async () => {
    // localStorage.removeItem("app-access-token");
    // localStorage.removeItem("app-refresh-token");
    // localStorage.removeItem("tokens");
    // localStorage.removeItem("user");
    // window.location.reload();
    // window.location.href = "/";

    // dispatch(userLogoutAsyncThunk({navigate}))
    const tokens = JSON.parse(localStorage.getItem(`tokens`)) || null
    try {
      const res = await axios.post(`${process.env.REACT_APP_contentBasePath}auth/logout`, {
        refreshToken: tokens?.refresh?.token
      }, {
        headers: {
          "Authorization": `Bearer ${tokens?.access?.token}`
        }
      })
      if (res.status == 204 || res.status == 200) {
        toast.success('LogOut Successfully!!!')
        localStorage.clear()
        navigate('/')
        window.location.reload()
      }
    } catch (error) {
      console.log('error loging out ', error)
      localStorage.clear()
      navigate('/')
      window.location.reload()
    }

  };
  return (
    <div className="navbar_section">
      <Navbar expand="lg" bg="transparent" variant="light">
        <div className="container">
          <Navbar.Brand href="/">
            <img src="/images/logo.svg" alt="Logo" className="logo-img" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarSupportedContent" />
          <Navbar.Collapse id="navbarSupportedContent">
            <Nav className="ms-auto mt-lg-0 mt-3 buttons-portion link">
            <Nav.Link as={Link} to="/">
                Home
              </Nav.Link>
              <Nav.Link as={Link} to="/pricing">
                Pricing
              </Nav.Link>
              <Nav.Link as={Link} to="/affiliate-program" target="_blank">Affiliate  <Badge>40% commission</Badge></Nav.Link>
              <Nav.Link as={Link} to="/support">Support</Nav.Link>
              {/* <Nav.Link as={Link} to="/training">
                Training
              </Nav.Link> */}
            </Nav>
            <Nav className="ml-auto pt-lg-0 pt-2 buttons-portion">
              {!user ? (
                <>
                  <Nav.Link as={Link} to="/signup">
                    Sign Up
                  </Nav.Link>
                  <Nav.Link as={Link} to="/login">
                    Log in
                  </Nav.Link>
                  <Nav.Link className="white-bg-btn" href="https://chromewebstore.google.com/detail/teachers-aid-skool-crm/pfhkopnkoopmbipojadfoanofmebagmc" target="_blank">
                    <button>
                      <img src="/images/chrome-store.svg" alt="Chrome" />
                      Add to Chrome
                    </button>
                  </Nav.Link>
                </>
              ) : (
                <div className="d-flex align-items-center buttons-portion">
                  <Nav.Link className="white-bg-btn" href="https://chromewebstore.google.com/detail/teachers-aid-skool-crm/pfhkopnkoopmbipojadfoanofmebagmc" target="_blank">
                    <button>
                      <img src="/images/chrome-store.svg" alt="Chrome" />
                      Move to extension
                    </button>
                  </Nav.Link>
                  <div className="profile-dropdown">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <IoPersonSharp />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item style={{ textTransform: "capitalize", background: "transparent", cursor: "default"}}>{user?.name}</Dropdown.Item>
                        <Dropdown.Item onClick={logoutHandler}>Log Out </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              )}
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </div>
  );
};

export default Header;
