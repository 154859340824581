import React from 'react'
import { Link } from "react-router-dom";
import { FaTwitter } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <div className='footer_box'>    
        <div className="footer_upper_content">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="logo_column">
                <img src="/images/white-logo.svg" alt="logo" className='logo_img' />
                <p className='pt-4 pb-3'>Teacher's Aid is a Chrome Extension designed to streamline classroom management, enhance communication, and support educators in their daily tasks.</p>
                <div className='icon_flex'>
                  <div>
                    <FaTwitter />
                  </div>
                  <div>
                    <FaFacebookSquare />
                  </div>
                  <div>
                    <BsInstagram />
                  </div>
                  <div>
                    <FaLinkedin />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-sm-6 mt-md-0 mt-4">
              <div className="content_column">
                <h6>Services</h6>
                <div>
                  <Link to="/pricing">Pricing</Link>
                  <Link to="/support">Features</Link>
                  <Link to="/support">Affiliate Program</Link>
                  <Link to="/support">FAQ</Link>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-sm-6 mt-md-0 mt-4">
              <div className="content_column">
                <h6>Legal</h6>
                <div>
                  <Link to="/policy">Privacy Policy</Link>
                  <Link to="/support">Terms & Conditions</Link>
                  <Link to="/refund">Refund Policy</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>

        <div className='lower_upper_content'>
          <div className="container">
          <p>Copyright © 2024 Teacher’s Aid®. All rights reserved.</p>
          </div>
        </div>
    </div>
  )
}

export default Footer
