import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import bookmarkimg from "../assets/images/bookmark.svg"
import { FaPlus } from "react-icons/fa6";
import { BiSolidPencil } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { IoChevronBackOutline } from "react-icons/io5";
import { RiSearch2Fill } from "react-icons/ri";
import profile from "../../assets/images/adam_wathan.png"
import { AiOutlineLike } from "react-icons/ai";
import { FaRegCommentDots } from "react-icons/fa";
import { LuExternalLink } from "react-icons/lu";
import bookmarkimg from "../../assets/images/bookmark.svg"
import Form from 'react-bootstrap/Form';
import Draftreminder from './Draft-reminder-model';

export default function Draftmodel() {
    const [show, setShow] = useState(false);
    const [showReminder, setShowReminder] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div className='model-draft-main-div'>
            <Button variant="primary" onClick={handleShow}>
                Launch demo modal
            </Button>

            <Modal show={show} onHide={handleClose} centered className='draft-model'>
                <Modal.Header closeButton>
                    <Modal.Title><img src={bookmarkimg} alt="bookmark" /> Bookmark this post</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='model-extension-body'>
                        <div className='default-body-listing'>
                            <div className='listing-box'>
                                <div className='names-heading-flexbox'>
                                    <div className='names-heading'>
                                        <div className='icon-box'>
                                            <img src={profile} alt="profile" />
                                        </div>
                                        <div className='name-box'>
                                            <h6>Janice Hargett</h6>
                                            <p>I got my first brand deal!</p>
                                        </div>
                                    </div>
                                    <div className="button-box">
                                        <button >
                                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_2081_19175)">
                                                    <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M4.1969 10.8859C4.39801 11.0489 4.42887 11.3441 4.26584 11.5452L2.23914 14.0452C2.07611 14.2463 1.78093 14.2772 1.57982 14.1141C1.37872 13.9511 1.34786 13.6559 1.51089 13.4548L3.53758 10.9548C3.70061 10.7537 3.9958 10.7229 4.1969 10.8859ZM10.803 10.8859C11.0041 10.7229 11.2993 10.7537 11.4623 10.9548L13.4891 13.4548C13.6522 13.6559 13.6213 13.9511 13.4202 14.1141C13.2191 14.2772 12.9239 14.2463 12.7609 14.0452L10.7341 11.5452C10.5711 11.3441 10.6019 11.0489 10.803 10.8859Z" fill="#868686" />
                                                    <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M4.92739 1.06231C5.03112 1.29951 4.92292 1.57588 4.68572 1.6796C3.44175 2.22358 2.38492 3.11763 1.64039 4.23741C1.49706 4.45299 1.2061 4.51156 0.990515 4.36822C0.774935 4.22488 0.716369 3.93392 0.859707 3.71834C1.70284 2.45026 2.89963 1.43742 4.3101 0.820637C4.5473 0.716913 4.82367 0.825114 4.92739 1.06231ZM10.0727 1.06231C10.1764 0.825114 10.4528 0.716913 10.69 0.820637C12.1005 1.43742 13.2973 2.45026 14.1404 3.71834C14.2837 3.93392 14.2252 4.22488 14.0096 4.36822C13.794 4.51156 13.503 4.45299 13.3597 4.23741C12.6152 3.11763 11.5583 2.22358 10.3144 1.6796C10.0772 1.57588 9.96898 1.29951 10.0727 1.06231Z" fill="#868686" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 13.75C10.6066 13.75 13.125 11.2316 13.125 8.125C13.125 5.0184 10.6066 2.5 7.5 2.5C4.3934 2.5 1.875 5.0184 1.875 8.125C1.875 11.2316 4.3934 13.75 7.5 13.75ZM7.81219 5.60753C7.81219 5.37009 7.60233 5.17761 7.34344 5.17761C7.08456 5.17761 6.87469 5.37009 6.87469 5.60753V8.47367C6.87469 8.61742 6.95302 8.75165 7.08343 8.83139L8.95843 9.97784C9.17383 10.1095 9.46487 10.0562 9.60847 9.8586C9.75207 9.66104 9.69386 9.39412 9.47846 9.26241L7.81219 8.24358V5.60753Z" fill="#868686" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_2081_19175">
                                                        <rect width="15" height="15" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </button>
                                        <button>
                                            <LuExternalLink />
                                        </button>
                                    </div>
                                </div>
                                <p>I received a 24 point violation after no previous violations. I only had TTS for a few weeks prior to...</p>
                                <div className='card-bottom'>
                                    <button>
                                        <AiOutlineLike />
                                        <span>1.6k Likes</span>
                                    </button>
                                    <button>
                                        <FaRegCommentDots />
                                        <span>200 Comments</span>
                                    </button>
                                </div>
                            </div>

                            <div className='listing-box'>
                                <div className='names-heading-flexbox'>
                                    <div className='names-heading'>
                                        <div className='icon-box'>
                                            <img src={profile} alt="profile" />
                                        </div>
                                        <div className='name-box'>
                                            <h6>Janice Hargett</h6>
                                            <p>I got my first brand deal!</p>
                                        </div>
                                    </div>
                                    <div className="button-box" onClick={() => setShowReminder((pre) => !pre)}>
                                        <button>
                                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M4.1969 10.8859C4.39801 11.0489 4.42887 11.3441 4.26584 11.5452L2.23914 14.0452C2.07611 14.2463 1.78093 14.2772 1.57982 14.1141C1.37872 13.9511 1.34786 13.6559 1.51089 13.4548L3.53758 10.9548C3.70061 10.7537 3.9958 10.7229 4.1969 10.8859ZM10.803 10.8859C11.0041 10.7229 11.2993 10.7537 11.4623 10.9548L13.4891 13.4548C13.6522 13.6559 13.6213 13.9511 13.4202 14.1141C13.2191 14.2772 12.9239 14.2463 12.7609 14.0452L10.7341 11.5452C10.5711 11.3441 10.6019 11.0489 10.803 10.8859Z" fill="#E74C3C" />
                                                <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M4.92739 1.06231C5.03112 1.29951 4.92292 1.57588 4.68572 1.6796C3.44175 2.22358 2.38492 3.11763 1.64039 4.23741C1.49706 4.45299 1.2061 4.51156 0.990515 4.36822C0.774935 4.22488 0.716369 3.93392 0.859707 3.71834C1.70284 2.45026 2.89963 1.43742 4.3101 0.820637C4.5473 0.716913 4.82367 0.825114 4.92739 1.06231ZM10.0727 1.06231C10.1764 0.825114 10.4528 0.716913 10.69 0.820637C12.1005 1.43742 13.2973 2.45026 14.1404 3.71834C14.2837 3.93392 14.2252 4.22488 14.0096 4.36822C13.794 4.51156 13.503 4.45299 13.3597 4.23741C12.6152 3.11763 11.5583 2.22358 10.3144 1.6796C10.0772 1.57588 9.96898 1.29951 10.0727 1.06231Z" fill="#E74C3C" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 13.75C10.6066 13.75 13.125 11.2316 13.125 8.125C13.125 5.0184 10.6066 2.5 7.5 2.5C4.3934 2.5 1.875 5.0184 1.875 8.125C1.875 11.2316 4.3934 13.75 7.5 13.75ZM7.81219 5.60753C7.81219 5.37009 7.60233 5.17761 7.34344 5.17761C7.08456 5.17761 6.87469 5.37009 6.87469 5.60753V8.47367C6.87469 8.61742 6.95302 8.75165 7.08343 8.83139L8.95843 9.97784C9.17383 10.1095 9.46487 10.0562 9.60847 9.8586C9.75207 9.66104 9.69386 9.39412 9.47846 9.26241L7.81219 8.24358V5.60753Z" fill="#E74C3C" />
                                            </svg>
                                            {showReminder && <Draftreminder show={showReminder} setShow={setShowReminder} />}
                                        </button>

                                        <button>
                                            <LuExternalLink />
                                        </button>
                                    </div>
                                </div>
                                <p>I received a 24 point violation after no previous violations. I only had TTS for a few weeks prior to...</p>
                                <div className='card-bottom'>
                                    <button>
                                        <AiOutlineLike />
                                        <span>1.6k Likes</span>
                                    </button>
                                    <button>
                                        <FaRegCommentDots />
                                        <span>200 Comments</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className='body-heading mt-3'>
                            <p>Select folders</p>
                            <button><FaPlus /> Add new</button>
                        </div>

                        <div className='body-listing'>
                            <div className='selection-box'>
                                <Form.Check aria-label="option 1" />
                                <div className='listing-box'>
                                    <div className='names-heading'>
                                        <div className='icon-box'>
                                            I
                                        </div>
                                        <div className='badge-box'>
                                            <p>Inspirational</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='selection-box'>
                                <Form.Check aria-label="option 1" />
                                <div className='listing-box'>
                                    <div className='names-heading'>
                                        <div className='icon-box'>
                                            E
                                        </div>
                                        <div className='badge-box'>
                                            <p>Educational</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='selection-box'>
                                <Form.Check aria-label="option 1" />
                                <div className='listing-box'>
                                    <div className='names-heading'>
                                        <div className='icon-box'>
                                            N
                                        </div>
                                        <div className='badge-box'>
                                            <p>Nutrition</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='selection-box'>
                                <Form.Check aria-label="option 1" />
                                <div className='listing-box'>
                                    <div className='names-heading'>
                                        <div className='icon-box'>
                                            N
                                        </div>
                                        <div className='badge-box'>
                                            <p>Nutrition</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='selection-box'>
                                <Form.Check aria-label="option 1" />
                                <div className='listing-box'>
                                    <div className='names-heading'>
                                        <div className='icon-box'>
                                            N
                                        </div>
                                        <div className='badge-box'>
                                            <p>Nutrition</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='selection-box'>
                                <Form.Check aria-label="option 1" />
                                <div className='listing-box'>
                                    <div className='names-heading'>
                                        <div className='icon-box'>
                                            N
                                        </div>
                                        <div className='badge-box'>
                                            <p>Nutrition</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='selection-box'>
                                <Form.Check aria-label="option 1" />
                                <div className='listing-box'>
                                    <div className='names-heading'>
                                        <div className='icon-box'>
                                            N
                                        </div>
                                        <div className='badge-box'>
                                            <p>Nutrition</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}