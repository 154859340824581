import React, { useState } from "react";
import Generaltabs from "./Generaltabs";
import Secondgeneraltab from "./Secondgeneraltab";
import { FaAngleDoubleDown } from "react-icons/fa";
import { LuCopy } from "react-icons/lu";
import { toast } from "react-toastify";


export default function Affilatescondtabs() {
  const [activeTab, setActiveTab] = useState("Email1");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const copyToClipboard = () => {
    const content = document.querySelector('.tab-content-1').innerText;
    navigator.clipboard.writeText(content).then(() => {
      toast('Content copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };



  return (
    <div className="tabs-container">
      {/* Tab Headers */}
      <div className="tabs-header">
        <button
          className={`tab-button ${activeTab === "Email1" ? "active" : ""}`}
          onClick={() => handleTabClick("Email1")}
        >
          Email # 1
        </button>
        <button
          className={`tab-button ${activeTab === "Email2" ? "active" : ""}`}
          onClick={() => handleTabClick("Email2")}
        >
          Email # 2
        </button>
        <button
          className={`tab-button ${activeTab === "Email3" ? "active" : ""}`}
          onClick={() => handleTabClick("Email3")}
        >
          Email # 3
        </button>
        <button
          className={`tab-button ${activeTab === "Email4" ? "active" : ""}`}
          onClick={() => handleTabClick("Email4")}
        >
          Email # 4
        </button>
        <button
          className={`tab-button ${activeTab === "Email5" ? "active" : ""}`}
          onClick={() => handleTabClick("Email5")}
        >
          Email # 5
        </button>
        <button
          className={`tab-button ${activeTab === "Email6" ? "active" : ""}`}
          onClick={() => handleTabClick("Email6")}
        >
          Email # 6
        </button>
        <button
          className={`tab-button ${activeTab === "Email7" ? "active" : ""}`}
          onClick={() => handleTabClick("Email7")}
        >
          Email # 7
        </button>
        <button
          className={`tab-button ${activeTab === "Email8" ? "active" : ""}`}
          onClick={() => handleTabClick("Email8")}
        >
          Email # 8
        </button>
        <button
          className={`tab-button ${activeTab === "Email9" ? "active" : ""}`}
          onClick={() => handleTabClick("Email9")}
        >
          Email # 9
        </button>
        <button
          className={`tab-button ${activeTab === "Email10" ? "active" : ""}`}
          onClick={() => handleTabClick("Email10")}
        >
          Email # 10
        </button>
        <button
          className={`tab-button ${activeTab === "Email11" ? "active" : ""}`}
          onClick={() => handleTabClick("Email11")}
        >
          Email # 11
        </button>
        <button
          className={`tab-button ${activeTab === "Email12" ? "active" : ""}`}
          onClick={() => handleTabClick("Email12")}
        >
          Email # 12
        </button>
        <button
          className={`tab-button ${activeTab === "Email13" ? "active" : ""}`}
          onClick={() => handleTabClick("Email13")}
        >
          Email # 13
        </button>
        <button
          className={`tab-button ${activeTab === "Email14" ? "active" : ""}`}
          onClick={() => handleTabClick("Email14")}
        >
          Email # 14
        </button>
        <button
          className={`tab-button ${activeTab === "Email15" ? "active" : ""}`}
          onClick={() => handleTabClick("Email15")}
        >
          Email # 15
        </button>

      </div>

      {/* Tab Content */}
      <div className="tabs-content">
        <div className="general-tab">
          {activeTab === "Email1" && <div className="tab-content tab-content-1">
            <div className="scroller-general-div">
                <div className="general-tab-block">
                  <strong>Preview Text:</strong>
                  <p>Skool Isn’t Enough. Here’s Why.</p>
                </div>
                <div className="general-tab-block">
                  <strong>Subject</strong>
                  <p>✔ Skool isn’t built to make you money. 😳</p>
                </div>

                <div className="general-tab-block pb-0">
                  <strong>Email:</strong>
                  <p>Let’s be real: Skool is great for hosting communities… but managing your leads? Tracking follow-ups? </p>
                  <p>Turning conversations into clients?</p>
                  <p className="spacing-general-div">Not so much.</p>
                  <p>I learned the hard way that just running a Skool group doesn’t automatically grow your revenue.</p>
                  <p>💬 Messages get lost.</p>
                  <p>📌 Promising leads disappear.
                  </p>
                  <p className="spacing-general-div">🛑 Opportunities die before they start.</p>
                  <p className="spacing-general-div">That’s why I started using <strong>Teacher’s Aid.</strong> </p>
                  <p className="spacing-general-div">It turns your Skool group into an actual business tool. Track leads, automate follow-ups, and make sure no potential client slips through the cracks.</p>
                  <p>If you’re running your business on Skool without Teacher’s Aid, you’re leaving money behind.</p>
                </div>
           
            </div>
          </div>}
          {activeTab === "Email2" && <div className="tab-content tab-content-1">
            <div className="scroller-general-div">
                <div className="general-tab-block">
                  <strong>Preview Text:</strong>
                  <p>Stop Running Your Skool Group Like a Hobby.</p>
                </div>
                <div className="general-tab-block">
                  <strong>Subject</strong>
                  <p>✔ Your Skool group deserves better</p>
                </div>

                <div className="general-tab-block pb-0">
                  <strong>Email Body:</strong>
                  <p className="spacing-general-div">There are two types of Skool group owners: </p>
                  <p>1: The ones who let conversations collect dust and hope for the best.</p>
                  <p className="spacing-general-div">2: The ones who <i> actually use their group to drive revenue.</i></p>
                  <p className="spacing-general-div">If you’re not in the second category, it’s not your fault.</p>
                  <p className="spacing-general-div">Skool was built for community, NOT business.</p>
                  <p className="spacing-general-div">That’s where <strong>Teacher’s Aid</strong> comes in. It bridges the gap between “cool community tool” and a <i>real revenue machine.</i>
                  </p>
                  <ul className="spacing-general-div">
                    <li>
                       Keep track of every lead without spreadsheets.
                    </li>
                    <li>
                      Automate your leads to email follow-ups.
                    </li>
                    <li>
                      Organize everything in one place.
                    </li>
                  </ul>
                  <p className="spacing-general-div">This tool transforms Skool from “kind of useful” to <i> absolutely essential.</i>
                  </p>
                  <p>Don’t let your Skool group stay a hobby. Turn it into the business asset it was meant to be.</p>
                </div>
            </div>
          </div>}
          {activeTab === "Email3" && <div className="tab-content tab-content-1"><div className="scroller-general-div">
              <div className="general-tab-block">
                <strong>Preview Text:</strong>
                <p>You’re Losing Sales on Skool. Here’s the Proof.</p>
              </div>
              <div className="general-tab-block">
                <strong>Subject</strong>
                <p>✔ Proof you’re leaving money on the table.</p>
              </div>

              <div className="general-tab-block pb-0">
                <strong>Email Body:</strong>
                <p className="spacing-general-div">Do this right now:</p>
                <p>1: Log into Skool.</p>
                <p>2: Scroll through your DMs.</p>
                <p className="spacing-general-div">3: Count how many messages you forgot to follow up on.</p>
                <p className="spacing-general-div">💸 That’s how much money you’ve missed out on.</p>
                <p>Skool is amazing for connecting with your community, but it’s NOT built to help you stay on top of leads, follow-ups, or sales.
                </p>
                <p className="spacing-general-div">That’s why I recommend <strong>Teacher’s Aid.</strong>
                </p>
                <p className="spacing-general-div">It’s a Chrome Extension designed to track conversations, leads, and deals inside Skool so nothing gets missed.</p>
                <p className="spacing-general-div">If you’re serious about using Skool to grow your business, this is the missing piece.</p>
                <p>Start Tracking Your Sales with Teacher’s Aid →</p>
              </div>
          </div></div>}
          {activeTab === "Email4" && <div className="tab-content tab-content-1"><div className="scroller-general-div">
              <div className="general-tab-block">
                <strong>Preview Text:</strong>
                <p>Tired of Losing Leads on Skool?</p>
              </div>
              <div className="general-tab-block">
                <strong>Subject</strong>
                <p>✔ Tired of losing leads on Skool?</p>
              </div>

              <div className="general-tab-block pb-0">
                <strong>Email Body:</strong>
                <p>You’ve got the perfect Skool group.</p>
                <p>✅ Active members.</p>
                <p>✅ Engaged conversations.</p>
                <p className="spacing-general-div">✅ Great potential clients.</p>
                <p className="spacing-general-div">So why are your sales stuck?</p>
                <p>Here’s the problem:
                </p>
                <p className="spacing-general-div">Skool wasn’t designed to track leads, follow-ups, or deals.
                </p>
                <p className="spacing-general-div">But that’s where <strong>Teacher’s Aid</strong> comes in.</p>
                <p>With Teacher’s Aid, you can:</p>
                <p>🎯 Organize leads without missing a single one.</p>
                <p>⏰ Set follow-up reminders that actually get done.</p>
                <p className="spacing-general-div">📊 Turn conversations into closed deals.</p>
                <p className="spacing-general-div">If you’re tired of leaving money on the table, Teacher’s Aid is your solution.</p>
                <p>Get Started Now →</p>
              </div>
          </div>
          </div>}
          {activeTab === "Email5" && <div className="tab-content tab-content-1"><div className="scroller-general-div">
              <div className="general-tab-block">
                <strong>Preview Text: </strong>
                <p>Skool Isn’t Enough. Here’s Why.</p>
              </div>

              <div className="general-tab-block">
                <strong>Subject</strong>
                <p>✔ Skool isn’t built to make you money. 😳</p>
              </div>

              <div className="general-tab-block pb-0">
                <strong>Email Body:</strong>
                <p className="spacing-general-div">Let’s have a brutally honest conversation.</p>
                <p>If you’re running a Skool community and wondering why your sales don’t reflect the engagement, here’s the hard truth:</p>
                <strong className="spacing-general-div">Skool wasn’t designed to grow your revenue.</strong>
                <p>Sure, it’s amazing for hosting your audience and fostering conversations. But when it comes to managing leads, tracking follow-ups, and actually closing sales?</p>
                <p className="spacing-general-div">Skool falls short.</p>
                <p><strong>💬 Lost conversations:</strong> You’ve probably scrolled past messages that were pure gold.</p>
                <p><strong>🛑 Missed follow-ups:</strong> Without a system, it’s impossible to remember everyone.
                </p>
                <p className="spacing-general-div"><strong>💸 Lost revenue:</strong>  Every missed message = money out of your pocket.
                </p>
                <p className="spacing-general-div">That’s exactly why I started using <strong>Teacher’s Aid—</strong>because I refused to let my Skool group stay just a “nice-to-have.”</p>
                <p>Here’s what happened when I switched:</p>
                <p><strong>✅ Tracked every lead.</strong> No more guessing who I spoke to or where they came from.</p>
                <p><strong>⏰ Followed up automatically.</strong> I turned forgotten conversations into actual deals.</p>
                <p className="spacing-general-div"><strong>💥 Closed more sales. </strong> Skool became the revenue machine I always wanted.</p>
                <p>Let me be clear:</p>
                <p className="spacing-general-div">If you’re not using Teacher’s Aid, you’re running your Skool group at a <i>fraction</i> of its potential.
                </p>
                <strong className="spacing-general-div">💡 This is your moment to stop bleeding revenue and start closing deals. Teacher’s Aid turns your group into the business tool you’ve been waiting for.</strong>
                <p>👉 Sign up for Teacher’s Aid now → </p>
              </div>

          </div></div>}
          {activeTab === "Email6" && <div className="tab-content tab-content-1"><div className="scroller-general-div">
              <div className="general-tab-block">
                <strong>Preview Text: </strong>
                <p>Stop Running Your Skool Group Like a Hobby.</p>
              </div>

              <div className="general-tab-block">
                <strong>Subject</strong>
                <p>✔ Is your Skool group just a hobby?</p>
              </div>

              <div className="general-tab-block pb-0">
                <strong>Email Body:</strong>
                <p className="spacing-general-div">Let me ask you something.</p>
                <p className="spacing-general-div">What’s the goal of your Skool community?</p>
                <ul className="spacing-general-div">
                  <li>To connect with your audience?</li>
                  <li>To serve your clients?</li>
                  <li>To grow your business?</li>
                </ul>
                <p>If it’s the last one, let’s talk about the elephant in the room:</p>
                <p className="spacing-general-div">Skool wasn’t designed to help you track leads, follow-ups, or sales.</p>
                <p>That’s why so many group owners fall into the same trap:</p>
                <p className="spacing-general-div">They focus on building community but lose out on the revenue opportunities sitting right in front of them.
                </p>
                <strong className="spacing-general-div">That was me.
                </strong>
                <p className="spacing-general-div">I spent months building an engaged Skool group, only to realize I had zero visibility into who was ready to buy or who needed follow-ups. Leads slipped through my fingers, conversations were forgotten, and my business suffered for it.
                </p>
                <p className="spacing-general-div">Then I found <strong>Teacher’s Aid.</strong></p>
                <p>This tool transformed how I run my group:</p>
                <p><strong>✅ Every lead is tracked.</strong> I always know where people are in the pipeline.</p>
                <p><strong>✅ Follow-ups are automatic. </strong> No more missed opportunities.</p>
                <p className="spacing-general-div"><strong>✅ Sales are consistent. </strong> My Skool group became a predictable source of revenue.</p>
                <strong className="spacing-general-div">🚨 Every day you run your group without Teacher’s Aid is a day you lose clients, sales, and growth. It’s time to stop treating your Skool group like a hobby and start running it like a business.</strong>
                <p>👉 Click here to get started → </p>
              </div>
            </div></div>}
          {activeTab === "Email7" && <div className="tab-content tab-content-1"><div className="scroller-general-div">
              <div className="general-tab-block">
                <strong>Preview Text: </strong>
                <p>The Hard Truth About Skool.</p>
              </div>

              <div className="general-tab-block">
                <strong>Subject</strong>
                <p>✔  Skool is great—but not enough.</p>
              </div>

              <div className="general-tab-block pb-0">
                <strong>Email Body:</strong>
                <p className="spacing-general-div">I’ll admit it: I was all-in on Skool.</p>
                <p className="spacing-general-div">When I started my group, I thought it would handle everything: conversations, leads, sales… the works.</p>
                <p className="spacing-general-div">It didn’t take long to realize I was wrong.</p>
                <p>Yes, Skool is incredible for hosting a community. But when it came to tracking leads, organizing conversations, and closing deals?</p>
                <p className="spacing-general-div">It was chaos.</p>
                <p>💬 I lost track of promising leads.
                </p>
                <p>📌 I forgot to follow up with people ready to buy.
                </p>
                <p>💸 I left money on the table—constantly.</p>
                <p className="spacing-general-div">I knew there had to be a better way, and that’s when I found <strong>Teacher’s Aid.</strong></p>
                <p className="spacing-general-div">This tool changed the game for me:</p>
                <ul className="spacing-general-div">
                  <li><strong>No more missed messages.</strong>Every lead is tracked automatically.</li>
                  <li><strong>No more lost sales.</strong>Follow-ups are handled without me lifting a finger.</li>
                  <li><strong>No more stress.</strong>My group is now organized and profitable.</li>
                </ul>
                <strong className="spacing-general-div">🔥 If you’re serious about turning your Skool group into a revenue-generating asset, you NEED Teacher’s Aid. Don’t learn the hard way like I did.</strong>
                <p>👉 Sign up now and start closing more deals →</p>
              </div>
          </div></div>}
          {activeTab === "Email8" && <div className="tab-content tab-content-1"><div className="scroller-general-div"> 
            <div className="general-tab-block">
              <strong>Preview Text: </strong>
              <p>Are You Tired of Losing Leads?</p>
            </div>

            <div className="general-tab-block">
              <strong>Subject</strong>
              <p>✔  This is why your Skool group isn’t growing.</p>
            </div>

            <div className="general-tab-block pb-0">
              <strong>Email Body:</strong>
              <p>Let me guess:</p>
              <p>You’ve built an amazing Skool community.</p>
              <p>✅ Active members.</p>
              <p>✅ Engaged conversations.</p>
              <p className="spacing-general-div">✅ Potential clients everywhere.</p>
              <p className="spacing-general-div">But somehow, the sales aren’t happening.
              </p>
              <p>Here’s why:
              </p>
              <p className="spacing-general-div">Skool wasn’t built for lead tracking, follow-ups, or deal management.</p>
              <p>I learned this the hard way.</p>
              <p>For months, I poured time and energy into my group, only to watch leads slip through the cracks.</p>

              <p className="spacing-general-div">Messages were forgotten. Follow-ups were missed. Opportunities were wasted.</p>
              <strong className="spacing-general-div">That’s where Teacher’s Aid comes in.</strong>
              <p>This tool was designed to fix everything Skool lacks:</p>
              <p><strong>🎯 Track leads effortlessly.</strong>Never lose a conversation again.</p>
              <p><strong>⏰ Automate follow-ups.</strong>Stop relying on memory and let the system do the work.</p>
              <p className="spacing-general-div"><strong>💸 Close more deals.</strong>Turn engagement into consistent revenue.</p>
              <strong className="spacing-general-div">💡 Your group already has the potential. Teacher’s Aid unlocks it. Don’t let another lead slip through your fingers.</strong>
              <p>👉 Start using Teacher’s Aid today →</p>
            </div>
          
          </div></div>}
          {activeTab === "Email9" && <div className="tab-content tab-content-1"><div className="scroller-general-div">
            <div className="general-tab-block">
              <strong>Preview Text: </strong>
              <p>How to Fix Your Skool Lead Problem.</p>
            </div>

            <div className="general-tab-block">
              <strong>Subject</strong>
              <p>✔ The tool that solves Skool’s biggest flaw.</p>
            </div>

            <div className="general-tab-block pb-0">
              <strong>Email Body:</strong>
              <p className="spacing-general-div">If you’ve ever felt overwhelmed by managing your Skool group, I get it.</p>
              <p className="spacing-general-div">Here’s the problem: Skool was built to host communities, NOT to manage your leads or drive revenue.</p>
              <p className="spacing-general-div">That’s why most Skool group owners struggle to grow their businesses.</p>
              <ul className="spacing-general-div">
                <li>Messages get lost.</li>
                <li>Leads fall through the cracks.</li>
                <li>Follow-ups don’t happen.</li>
              </ul>
              <p className="spacing-general-div">I was in the same boat until I found<strong> Teacher’s Aid.</strong></p>

              <p>This Chrome Extension transformed how I run my group:</p>
              <p><strong>✅ Leads are tracked. </strong>No more spreadsheets or missed opportunities.</p>
              <p><strong>✅ Follow-ups are automated.</strong>My sales process runs on autopilot.</p>
              <p className="spacing-general-div"><strong>✅ Revenue is predictable.</strong>Skool finally works for my business.</p>
              <strong className="spacing-general-div">💥 This is the tool every Skool group owner needs. Stop losing leads and start growing today.</strong>
              <p>👉 Click here to get started →</p>
            </div>
          </div></div>}
          {activeTab === "Email10" && <div className="tab-content tab-content-1"><div className="scroller-general-div">
            <div className="general-tab-block">
              <strong>Preview Text: </strong>
              <p>How to Fix Your Skool Lead Problem.</p>
            </div>

            <div className="general-tab-block">
              <strong>Subject</strong>
              <p>✔ You can’t do it all yourself—here’s proof.</p>
            </div>

            <div className="general-tab-block pb-0">
              <strong>Email Body:</strong>
              <p className="spacing-general-div">Let’s be real for a second.</p>
              <p className="spacing-general-div">If you’re managing your Skool inbox by yourself, it’s only a matter of time before things start falling apart. </p>
              <p>🚨 Leads will be missed.</p>
              <p>🚨 Messages will go unanswered.</p>
              <p className="spacing-general-div">🚨 Conversations will slip through the cracks.</p>
              <p className="spacing-general-div">I get it—Skool wasn’t built for lead management. But here’s the problem: if you can’t stay on top of your inbox, your revenue will always suffer.</p>
              <p className="spacing-general-div">That’s why I started using<strong> Teacher’s Aid.</strong></p>

              <p>With Teacher’s Aid, you can:</p>
              <p><strong>✅ Add team members</strong>to manage your inbox—without stepping on each other’s toes.</p>
              <p>✅ Assign conversations and keep your priorities crystal clear.</p>
              <p className="spacing-general-div">✅ Treat your inbox like a well-oiled machine designed to close deals.</p>
              <p className="spacing-general-div">Now, instead of stressing over my inbox, my team works alongside me to organize leads, follow up seamlessly, and keep everything on track.</p>
              <p className="spacing-general-div">💡 Think of it like a personal assistant for your Skool community—someone who keeps you focused on what matters most: growing your business.
              </p>
              <strong className="spacing-general-div">🔥 Stop losing leads and start working smarter. It’s time to transform your Skool inbox into a sales powerhouse with Teacher’s Aid.
              </strong>
              <p>👉 Click here to sign up now →</p>
            </div>
         </div></div>}
          {activeTab === "Email11" && <div className="tab-content tab-content-1"><div className="scroller-general-div">
                    <div className="general-tab-block">
                    <strong>Preview Text: </strong>
                    <p>How to Fix Your Skool Lead Problem.</p>
            </div>

                    <div className="general-tab-block">
                        <strong>Subject</strong>
                        <p>✔ Don’t let your team turn your inbox into chaos.</p>
                    </div>

                    <div className="general-tab-block pb-0">
                        <strong>Email Body:</strong>
                        <p className="spacing-general-div">If you’ve ever tried to involve your team in your Skool inbox, you already know what happens:
                        </p>
                        <ul className="spacing-general-div">
                            <li>Conversations get duplicated.</li>
                            <li>Messages are missed.</li>
                            <li>Everyone’s confused about who’s doing what.</li>
                        </ul>
                        <p>The result? </p>
                        <p className="spacing-general-div">Your leads slip through the cracks, your sales suffer, and your team ends up wasting time.</p>
                        <p className="spacing-general-div">That’s why I swear by <strong>Teacher’s Aid.</strong></p>
                        <p className="spacing-general-div">Here’s what Teacher’s Aid does that Skool doesn’t:</p>
                        <ul className="spacing-general-div">
                            <li><strong>Organized team collaboration.</strong>Everyone has a clear role and no one steps on each other’s toes.
                            </li>
                            <li><strong>Focused conversations.</strong>Your team keeps leads top-of-mind and nothing gets missed.
                            </li>
                            <li><strong>Inbox clarity.</strong>Even with multiple team members, your Skool inbox stays clean and actionable.</li>
                        </ul>
                        <p className="spacing-general-div">This tool turns your inbox into a machine—whether you’re managing it solo or with a team.
                        </p>
                        <strong className="spacing-general-div">🔥 Ready to turn chaos into clarity? Let Teacher’s Aid show you how.</strong>
                        <p>👉 Start using Teacher’s Aid today →</p>
                    </div>
               </div></div>}
          {activeTab === "Email12" && <div className="tab-content tab-content-1"><div className="scroller-general-div">
            <div className="general-tab-block">
              <strong>Preview Text: </strong>
              <p>The Inbox Secret Skool Never Told You.</p>
            </div>

            <div className="general-tab-block">
              <strong>Subject</strong>
              <p>Your Skool inbox is costing you money.</p>
            </div>

            <div className="general-tab-block pb-0">
              <strong>Email Body:</strong>
              <p>Here’s a little secret:</p>
              <p className="spacing-general-div">Your Skool inbox isn’t built for teams—or sales. </p>
              <p className="spacing-general-div">But what if I told you there’s a better way?</p>
              <p>With <strong>Teacher’s Aid</strong>, your Skool inbox can become a well-organized, revenue-driving machine.</p>
              <p>Here’s how:</p>
              <p>✅ Add team members to handle leads, follow-ups, and organization.</p>
              <p>✅ Assign tasks and conversations without creating confusion.</p>
              <p className="spacing-general-div">✅ Keep the most important leads front and center so nothing gets missed.</p>
              <p className="spacing-general-div">It’s like having a personal assistant for your inbox—helping you and your team stay laser-focused on the conversations that drive revenue.</p>
              <p className="spacing-general-div">Don’t let your inbox hold you back. Turn your Skool community into a growth engine with Teacher’s Aid.</p>
              <p>👉 Sign up now and get started →</p>
            </div>
          </div></div>}
          {activeTab === "Email13" && <div className="tab-content tab-content-1"><div className="scroller-general-div">
            <div className="general-tab-block">
              <strong>Preview Text: </strong>
              <p>Struggling to Manage Your Skool Inbox?</p>
            </div>

            <div className="general-tab-block">
              <strong>Subject</strong>
              <p>Your inbox doesn’t have to be a bottleneck.</p>
            </div>

            <div className="general-tab-block pb-0">
              <strong>Email Body:</strong>
              <p className="spacing-general-div">If you’re spending hours managing your Skool inbox and still missing leads, you’re not alone.</p>
              <p className="spacing-general-div">Skool is amazing for community engagement, but when it comes to:</p>
              <ul className="spacing-general-div">
                <li>Keeping conversations organized...</li>
                <li>Managing leads with your team...</li>
                <li>And making sure follow-ups happen...</li>
              </ul>
              <p className="spacing-general-div">It falls short.</p>
              <p className="spacing-general-div">That’s where <strong>Teacher’s Aid</strong> comes in.</p>
              <p>With Teacher’s Aid, you can:</p>
              <p>✅ Add team members to organize your inbox without stepping on toes.</p>
              <p>✅ Assign leads and follow-ups so everyone knows what to do.</p>
              <p className="spacing-general-div">✅ Keep the most important conversations front and center. </p>
              <p className="spacing-general-div">Think of it like hiring a personal assistant for your inbox—but way more affordable.</p>
              <p className="spacing-general-div">🔥 Don’t let your inbox slow you down. Take control with Teacher’s Aid and watch your Skool community become your most powerful sales tool.</p>
              <p>👉 Click here to start today →</p>
            </div>
          </div></div>}

          {activeTab === "Email14" && <div className="tab-content tab-content-1"><div className="scroller-general-div">
            <div className="general-tab-block">
              <strong>Preview Text: </strong>
              <p>How I Fixed My Inbox Nightmare.</p>
            </div>

            <div className="general-tab-block">
              <strong>Subject</strong>
              <p>The inbox problem no one talks about.</p>
            </div>

            <div className="general-tab-block pb-0">
              <strong>Email Body:</strong>
              <p className="spacing-general-div">When I first started using Skool, I thought I had it all figured out.</p>
              <ul className="spacing-general-div">
                <li>My community was growing.</li>
                <li>My audience was engaged.</li>
                <li>The leads were pouring in.</li>
              </ul>
           <p className="spacing-general-div">But my inbox? That was another story.</p>
           <p className="spacing-general-div">I couldn’t keep track of who I talked to, missed important follow-ups, and when I added team members to help, it just made things worse.</p>
           <p className="spacing-general-div">That’s when I found <strong>Teacher’s Aid.</strong></p>
           <p>Here’s how it saved my sanity:</p>
           <p>✅ My team can now jump into the inbox and take over—without creating chaos.</p>
           <p>✅ Important leads stay at the top of our list.</p>
           <p className="spacing-general-div">✅ Conversations are assigned and followed up on automatically.</p>
           <p className="spacing-general-div">Now, my inbox is no longer a bottleneck—it’s my best tool for growing revenue.</p>
           <strong className="spacing-general-div">💡 Don’t let your inbox hold you back. Teacher’s Aid makes managing your Skool community easier than ever.</strong>
           <p>👉 Sign up now and take control →</p>
            </div>
          </div></div>}
          {activeTab === "Email15" && <div className="tab-content tab-content-1"><div className="scroller-general-div">
            <div className="general-tab-block">
              <strong>Preview Text: </strong>
              <p>The Personal Assistant Your Inbox Needs.</p>
            </div>

            <div className="general-tab-block">
              <strong>Subject</strong>
              <p>What if your inbox could manage itself?</p>
            </div>

            <div className="general-tab-block pb-0">
              <strong>Email Body:</strong>
              <p>Imagine this:</p>
              <p className="spacing-general-div">You log into your Skool inbox, and instead of chaos, everything is perfectly organized.</p>
              <ul className="spacing-general-div">
                <li>Leads are tracked.</li>
                <li>Follow-ups are assigned.</li>
                <li>Important conversations are highlighted.</li>
              </ul>
              <p>And the best part?</p>
              <p className="spacing-general-div">Your team can help manage it all—without stepping on each other’s toes.</p>
              <p className="spacing-general-div">That’s exactly what <strong>Teacher’s Aid</strong> does.</p>
              <p>With Teacher’s Aid, your inbox becomes a seamless system where you and your team can:</p>
              <p>✅ Keep conversations organized and actionable.</p>
              <p>✅ Assign tasks and follow-ups without confusion.</p>
              <p className="spacing-general-div">✅ Focus on closing deals instead of searching for messages.</p>
              <p className="spacing-general-div">It’s like having a personal assistant built right into your Skool inbox.</p>
              <strong className="spacing-general-div">🔥 If you’re ready to stop losing leads and start growing your community like a pro, Teacher’s Aid is your answer.</strong>
              <p>👉 Get started now →</p>
            </div>
         </div></div>}
          <div className="copy-btn">
            <button onClick={copyToClipboard}>
            <LuCopy /> Copy
            </button>
          </div>
          {/* <div className="view-more-btn">
            <button>
              View more  <FaAngleDoubleDown />
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

