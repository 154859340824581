import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import bookmarkimg from "../../assets/images/bookmark.svg"
import { FaPlus } from "react-icons/fa6";
import { BiSolidPencil } from "react-icons/bi";
import { MdDelete } from "react-icons/md";

export default function Extensionpath() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div className='model-bookmark-main-div'>
            <Button variant="primary" onClick={handleShow}>
                Launch demo modal
            </Button>

            <Modal show={show} onHide={handleClose} centered className='model-bookmark'>
                <Modal.Header closeButton>
                    <Modal.Title><img src={bookmarkimg} alt="bookmark" />  My bookmarks</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='model-extension-body'>
                        <div className='body-heading'>
                            <p>My folders (10)</p>
                            <button><FaPlus /> Add new</button>
                        </div>
                        <div className='body-listing'>
                            <div className='listing-box'>
                                <div className='names-heading'>
                                    <div className='icon-box'>
                                        I
                                    </div>
                                    <div className='badge-box'>
                                        <p>Inspirational</p>
                                        <badge>102</badge>
                                    </div>
                                </div>
                                <div className='edit-headings'>
                                    <button>
                                        <BiSolidPencil />
                                        <span>Edit</span>
                                    </button>
                                    <button>
                                        <MdDelete/>
                                        <span>Delete</span>
                                    </button>
                                </div>
                            </div>
                            <div className='listing-box'>
                            <div className='names-heading'>
                                <div className='icon-box'>
                                    E
                                </div>
                                <div className='badge-box'>
                                    <p>Educational</p>
                                    <badge>102</badge>
                                </div>
                            </div>
                            <div className='edit-headings'>
                                <button>
                                    <BiSolidPencil />
                                    <span>Edit</span>
                                </button>
                                <button>
                                    <MdDelete/>
                                    <span>Delete</span>
                                </button>
                            </div>
                        </div>
                        <div className='listing-box'>
                            <div className='names-heading'>
                                <div className='icon-box'>
                                    N
                                </div>
                                <div className='badge-box'>
                                    <p>Nutrition</p>
                                    <badge>102</badge>
                                </div>
                            </div>
                            <div className='edit-headings'>
                                <button>
                                    <BiSolidPencil />
                                    <span>Edit</span>
                                </button>
                                <button>
                                    <MdDelete/>
                                    <span>Delete</span>
                                </button>
                            </div>
                        </div>
                            <div className='listing-box'>
                                <div className='names-heading'>
                                    <div className='icon-box'>

                                    </div>
                                    <div className='badge-box'>
                                        <input type="text" className='input-form' />
                                    </div>
                                </div>
                                <div className='edit-headings'>
                                    <button className='save-btn'>
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer> */}
            </Modal>
        </div>
    )
}