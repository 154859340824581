import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';

const Faqsection = ({scrollToPricingSection}) => {
    const [activeKey, setActiveKey] = useState("0");

    const toggleActive = (key) => {
      setActiveKey(activeKey === key ? null : key);
    };

    return (
        <div className='faq_section'>
            <div className="container">
                <div className="global-padding">
                    <h2>Frequently Asked Questions</h2>
                    <div className="accordian_box">
                        <Accordion activeKey={activeKey}>
                            <Accordion.Item eventKey="0" className={`accordion-item ${activeKey === "0" ? "active" : ""}`}>
                                <Accordion.Header onClick={() => toggleActive("0")}>How much can I earn?</Accordion.Header>
                                <Accordion.Body>
                                Earn up to 40% recurring commissions for each user who subscribes through your referral link.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1" className={`accordion-item ${activeKey === "1" ? "active" : ""}`}>
                                <Accordion.Header onClick={() => toggleActive("1")}>How do I get paid?</Accordion.Header>
                                <Accordion.Body>
                                    Teacher's Aid is a Chrome Extension designed to streamline and enhance your teaching experience. It helps you organize and manage classroom communications, tag and categorize chats, and sync information across teams, making it easier to stay organized and focused on teaching.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2" className={`accordion-item ${activeKey === "2" ? "active" : ""}`}>
                                <Accordion.Header onClick={() => toggleActive("2")}>Are there any costs to join?</Accordion.Header>
                                <Accordion.Body>
                                    Teacher's Aid is a Chrome Extension designed to streamline and enhance your teaching experience. It helps you organize and manage classroom communications, tag and categorize chats, and sync information across teams, making it easier to stay organized and focused on teaching.
                                </Accordion.Body>
                            </Accordion.Item>
                            {/* <Accordion.Item eventKey="3" className={`accordion-item ${activeKey === "3" ? "active" : ""}`}>
                                <Accordion.Header onClick={() => toggleActive("3")}>Is there an affiliate program for Teacher's Aid?</Accordion.Header>
                                <Accordion.Body>
                                    Teacher's Aid is a Chrome Extension designed to streamline and enhance your teaching experience. It helps you organize and manage classroom communications, tag and categorize chats, and sync information across teams, making it easier to stay organized and focused on teaching.
                                </Accordion.Body>
                            </Accordion.Item> */}
                        </Accordion>
                    </div>

                    <div className="gradiant-bg-btn">
                        <button onClick={() => scrollToPricingSection()}>
                            <img src="/images/chrome-store.svg" alt="Chrome" />
                            Start now
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Faqsection
