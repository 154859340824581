import React, { useState } from "react";
import Header from "../components/Header";
import Affilatetabs from "../components/Affilatetabs";
import Affilatefaq from "../components/Affilatefaq";
import Affilateearnsection from "../components/Affilateearnsection";
import Footer from "../components/Footer";
import profileing from "../assets/images/profile-img.png"
import bannerImg from "../assets/images/bg-line.svg"
import { FaStar } from "react-icons/fa";
import { FaRegStar } from "react-icons/fa";
import Affilatescondtabs from "../components/Affilatesecondtabs";

export default function Affilateprogram() {

  return (
    <div className="home_page_mian_div affilate-program">
      <div className="bg-img-div">
        <Header />
        <div className="optimize_crm">
          <div className="container">
            <div className="affilate-hero-section">
              <div className="optimize_crm_content">
                <h1 className="h1">
                  Join Teacher’s Aid Affiliate Program
                </h1>

                <p className="py-4 pe-lg-2">
                  Start earning commissions by sharing a tool that helps teams stay organized, close more deals, and <br /> manage conversations effortlessly.</p>

                <div className="button_content_box">
                  <div className="gradiant-bg-btn">
                    <button onClick={() => window.open(`https://affiliates.lemonsqueezy.com/programs/teachersaid`, '_blank')}>
                      Sign Up for Free
                    </button>
                  </div>
                </div>
              </div>
              <div className="optimize_video_box">
              <iframe
                src="https://www.youtube.com/embed/98-tPddG9VI?autoplay=1&loop=1&playlist=98-tPddG9VI&mute=1"
                title="YouTube video player"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
              ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid_sections">
        <div className="container">
          <div className="global-padding">
            <div className="row">
              <div className="col-lg-6 col-xl-5 my-auto order-2 order-lg-first">
                <div className="grid_content pe-lg-5">
                  <h2>Earn up to 40% <br /> commission <span>for every user <br /> you refer who upgrades to <br /> Teacher’s Aid. It’s simple, <br /> profitable, and rewarding.</span> </h2>
                  <div className="gradiant-bg-btn">
                    <button onClick={() => window.open(`https://affiliates.lemonsqueezy.com/programs/teachersaid`, '_blank')}>
                      Sign Up for Free
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-xl-7 my-auto ">
                <div className="img-right mb-lg-0 mb-5">
                  <img src="/images/Grid2.png" alt="collaborate" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* tabs Section */}
      <div className="bg-img-skool-section transparent">
        <div className="cards_section">
          <div className="container">
            <div className="global-padding">
              <div className="heading_box">
                <h2>Affiliate Resources</h2>
                <p>Get all the tools and resources you need to promote Teacher's Aid and <br /> maximize your commissions effortlessly.</p>
              </div>
              <div className="cards_grid_section">
                <div className="card">
                  <div className="card-body">
                    <div className="card-heading">
                      <h5>Social Posts</h5>
                      <p>Grab attention with engaging content designed to promote Teacher's Aid across your favorite platforms.</p>
                    </div>
                    <div className="tabs-portion">
                      <Affilatetabs />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Graphics card */}
      <div className="graphics_sections">
        <div className="container">
          <div className="global-padding">
            <div className="card">
              <div className="card-body">
                <h5>
                  Banners and Graphics
                </h5>
                <p>Grab eye-catching banners to promote Teacher’s Aid on your website, blog, or social media.</p>
                <div className="white-bg-btn">
                  <a href="https://drive.google.com/drive/folders/13kufUJlp3IY6v_3FN-47HTbSv1dBz1iw" target="_blank">
                    Download Assets
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*2 tabs Section */}
      <div className="bg-img-skool-section transparent">
        <div className="cards_section">
          <div className="container">
            <div className="global-padding">
              <div className="cards_grid_section">
                <div className="card">
                  <div className="card-body">
                    <div className="card-heading">
                      <h5>Email</h5>
                      <p>Use our professionally written emails to share Teacher’s Aid with your audience.</p>
                    </div>
                    <div className="tabs-portion">
                      <Affilatescondtabs />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="affilate-program">
        <div className="container">
          <div className="global-padding">
            <div className="affilate-program-card">
              <div className="card">
                <h2>
                  How our Affiliate  <br />
                  Program Works
                </h2>
                <p>
                  Discover how the Teacher's Aid Affiliate <br /> Program empowers you to share, promote, and <br /> earn like never before.
                </p>
                <div className="button_content_box">
                  <div className="gradiant-bg-btn">
                    <button onClick={() => window.open(`https://affiliates.lemonsqueezy.com/programs/teachersaid`, '_blank')}>
                      Sign Up for Free
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="counting-cards">
              <img src={bannerImg} alt="bannerImg" className="line-bg-img" />
              <div className="row">
                <div className="col-lg-4">
                  <div className="card one">
                  <div className="position-box">
                    <div className="boxes">
                      <p></p>
                    </div>
                    <p className="sign-up-paragraph">Sign Up for Free</p>
                    <p>Joining the Teacher’s Aid affiliate <br /> program is quick and easy. No  <br />upfront costs.</p>
                  </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card second">
                    <div className="position-box my-lg-0 my-5">
                    <div className="boxes">
                      <p></p>
                    </div>
                    <p className="sign-up-paragraph">Share Your Link</p>
                    <p>Use your unique referral link to <br /> promote Teacher’s Aid across your <br /> channels.</p>

                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card three">
                   <div className="position-box">
                    <div className="boxes">
                      <p></p>
                    </div>
                    <p className="sign-up-paragraph">Earn Commissions</p>
                    <p>Earn up to 40% recurring <br />commissions for every referral who <br /> subscribes.</p>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid_sections">
        <div className="container">
          <div className="global-padding">
            <div className="row">
              <div className="col-lg-6 col-xl-5 my-auto order-2 order-lg-first">
                <div className="grid_content pe-lg-5">
                  <h2>Why Promote Teacher’s Aid?</h2>
                  <p className="py-3">Help teachers stay organized while enjoying high <br /> commissions and ongoing rewards.</p>
                  <div className="flex_grid_content_box">
                    <div className="grid_listbox_flexbox">
                      <div className="icon_box">
                        <p>1</p>
                      </div>
                      <div className="grid_flex_content">
                        <p>High-Value Product</p>
                        <span>Teacher’s Aid helps thousands of users <br /> streamline their workflows, organize <br /> conversations, and close deals faster.</span>
                      </div>
                    </div>

                    <div className="grid_listbox_flexbox">
                      <div className="icon_box">
                        <p>2</p>
                      </div>
                      <div className="grid_flex_content">
                        <p>Generous Commissions</p>
                        <span>Earn up to 40% recurring commissions on every sale.</span>
                      </div>
                    </div>

                    <div className="grid_listbox_flexbox">
                      <div className="icon_box">
                        <p>3</p>
                      </div>
                      <div className="grid_flex_content">
                        <p>Easy Promotion</p>
                        <span>We provide all the marketing assets you <br /> need—banners, emails, and more—to get <br /> started fast.</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-xl-7 my-auto order-1 order-lg-last">
                <div className="img-right mb-lg-0 mb-5">
                  <img src="/images/Grid2.png" alt="collaborate" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-img-div orange">
        <div className="optimize_crm">
          <div className="container">
            <div className="affilate-hero-section">
              <div className="optimize_crm_content">
                <h1 className="h1">
                  See Teacher’s Aid in Action
                </h1>

                <p className="py-4 pe-lg-2">
                  Watch the Video to learn why teams love Teacher’s Aid and how it can help <br /> users manage leads, conversations, and follow-ups effortlessly.
                </p>
              </div>
              <div className="optimize_video_box">
              <iframe
  src="https://www.youtube.com/embed/S6P-hfYzaLo?autoplay=1&loop=1&playlist=S6P-hfYzaLo&mute=1"
  title="YouTube video player"
  frameBorder="0"
  allow="autoplay; encrypted-media"
  allowFullScreen
></iframe>

              </div>

              <div className="button_content_box">
                <div className="gradiant-bg-btn">
                  <button onClick={() => window.open(`https://affiliates.lemonsqueezy.com/programs/teachersaid`, '_blank')}>
                    Sign Up for Free
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="bg-img-skool-section transparent">
        <div className="cards_section">
          <div className="container">
            <div className="global-padding">
              <div className="heading_box">
                <h2>What Educators Are Saying</h2>
                <p>Real stories from teachers who transformed their workflow with Teacher's Aid.</p>
              </div>
              <div className="cards_education_grid_section">
                <div className="row gy-4">
                  <div className="col-lg-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-header">
                          <div className="img-box">
                            <img src={profileing} alt="user" />
                          </div>
                          <div>
                            <h6>
                              Sarah L.
                            </h6>
                            <p>Elementary School Teacher</p>
                          </div>
                        </div>
                        <div className="card-rating-portion">
                          <div className="star-portion">
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaRegStar />
                            <h6>Simplified my classroom
                              management!</h6>
                            <p>“Teacher’s Aid has been a lifesaver! I no longer lose track of conversations with parents or students. Everything is in one place, and I feel so organized!”</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-header">
                          <div className="img-box">
                            <img src={profileing} alt="user" />
                          </div>
                          <div>
                            <h6>
                              Sarah L.
                            </h6>
                            <p>Elementary School Teacher</p>
                          </div>
                        </div>
                        <div className="card-rating-portion">
                          <div className="star-portion">
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaRegStar />
                            <h6>Simplified my classroom
                              management!</h6>
                            <p>“Teacher’s Aid has been a lifesaver! I no longer lose track of conversations with parents or students. Everything is in one place, and I feel so organized!”</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-header">
                          <div className="img-box">
                            <img src={profileing} alt="user" />
                          </div>
                          <div>
                            <h6>
                              Sarah L.
                            </h6>
                            <p>Elementary School Teacher</p>
                          </div>
                        </div>
                        <div className="card-rating-portion">
                          <div className="star-portion">
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaRegStar />
                            <h6>Simplified my classroom
                              management!</h6>
                            <p>“Teacher’s Aid has been a lifesaver! I no longer lose track of conversations with parents or students. Everything is in one place, and I feel so organized!”</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-header">
                          <div className="img-box">
                            <img src={profileing} alt="user" />
                          </div>
                          <div>
                            <h6>
                              Sarah L.
                            </h6>
                            <p>Elementary School Teacher</p>
                          </div>
                        </div>
                        <div className="card-rating-portion">
                          <div className="star-portion">
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaRegStar />
                            <h6>Simplified my classroom
                              management!</h6>
                            <p>“Teacher’s Aid has been a lifesaver! I no longer lose track of conversations with parents or students. Everything is in one place, and I feel so organized!”</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-header">
                          <div className="img-box">
                            <img src={profileing} alt="user" />
                          </div>
                          <div>
                            <h6>
                              Sarah L.
                            </h6>
                            <p>Elementary School Teacher</p>
                          </div>
                        </div>
                        <div className="card-rating-portion">
                          <div className="star-portion">
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaRegStar />
                            <h6>Simplified my classroom
                              management!</h6>
                            <p>“Teacher’s Aid has been a lifesaver! I no longer lose track of conversations with parents or students. Everything is in one place, and I feel so organized!”</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-header">
                          <div className="img-box">
                            <img src={profileing} alt="user" />
                          </div>
                          <div>
                            <h6>
                              Sarah L.
                            </h6>
                            <p>Elementary School Teacher</p>
                          </div>
                        </div>
                        <div className="card-rating-portion">
                          <div className="star-portion">
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaRegStar />
                            <h6>Simplified my classroom
                              management!</h6>
                            <p>“Teacher’s Aid has been a lifesaver! I no longer lose track of conversations with parents or students. Everything is in one place, and I feel so organized!”</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <Affilatefaq />

      <Affilateearnsection />
      <Footer />
    </div>
  )
}