import React, { useState } from "react";
import Generaltabs from "./Generaltabs";
import { FaAngleDoubleDown } from "react-icons/fa";
import { LuCopy } from "react-icons/lu";
import { toast } from "react-toastify";

export default function Affilatetabs() {
  const [activeTab, setActiveTab] = useState("Post1");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const copyToClipboard = () => {
    const content = document.querySelector('.tab-content').innerText;
    navigator.clipboard.writeText(content).then(() => {
      toast('Content copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };
  return (
    <div className="tabs-container">
      {/* Tab Headers */}
      <div className="tabs-header">
        <button
          className={`tab-button ${activeTab === "Post1" ? "active" : ""}`}
          onClick={() => handleTabClick("Post1")}
        >
          Post 1
        </button>
        <button
          className={`tab-button ${activeTab === "Post2" ? "active" : ""}`}
          onClick={() => handleTabClick("Post2")}
        >
          Post 2
        </button>
        <button
          className={`tab-button ${activeTab === "Post3" ? "active" : ""}`}
          onClick={() => handleTabClick("Post3")}
        >
          Post 3
        </button>
        <button
          className={`tab-button ${activeTab === "Post4" ? "active" : ""}`}
          onClick={() => handleTabClick("Post4")}
        >
          Post 4
        </button>
        <button
          className={`tab-button ${activeTab === "Post5" ? "active" : ""}`}
          onClick={() => handleTabClick("Post5")}
        >
          Post 5
        </button>
        <button
          className={`tab-button ${activeTab === "Post6" ? "active" : ""}`}
          onClick={() => handleTabClick("Post6")}
        >
          Post6
        </button>
        <button
          className={`tab-button ${activeTab === "Post7" ? "active" : ""}`}
          onClick={() => handleTabClick("Post7")}
        >
          Post 7
        </button>
        <button
          className={`tab-button ${activeTab === "Post8" ? "active" : ""}`}
          onClick={() => handleTabClick("Post8")}
        >
          Post 8
        </button>
        <button
          className={`tab-button ${activeTab === "Post9" ? "active" : ""}`}
          onClick={() => handleTabClick("Post9")}
        >
          Post 9
        </button>
        <button
          className={`tab-button ${activeTab === "Post10" ? "active" : ""}`}
          onClick={() => handleTabClick("Post10")}
        >
          Post 10
        </button>
        <button
          className={`tab-button ${activeTab === "Post11" ? "active" : ""}`}
          onClick={() => handleTabClick("Post11")}
        >
          Post 11
        </button>
        <button
          className={`tab-button ${activeTab === "Post12" ? "active" : ""}`}
          onClick={() => handleTabClick("Post12")}
        >
          Post 12
        </button>
        <button
          className={`tab-button ${activeTab === "Post13" ? "active" : ""}`}
          onClick={() => handleTabClick("Post13")}
        >
          Post 13
        </button>
        <button
          className={`tab-button ${activeTab === "Post14" ? "active" : ""}`}
          onClick={() => handleTabClick("Post14")}
        >
          Post 14
        </button>
        <button
          className={`tab-button ${activeTab === "Post15" ? "active" : ""}`}
          onClick={() => handleTabClick("Post15")}
        >
          Post 15
        </button>
        <button
          className={`tab-button ${activeTab === "Post16" ? "active" : ""}`}
          onClick={() => handleTabClick("Post16")}
        >
          Post 16
        </button>
        <button
          className={`tab-button ${activeTab === "Post17" ? "active" : ""}`}
          onClick={() => handleTabClick("Post17")}
        >
          Post 17
        </button>
        <button
          className={`tab-button ${activeTab === "Post18" ? "active" : ""}`}
          onClick={() => handleTabClick("Post18")}
        >
          Post 18
        </button>
        <button
          className={`tab-button ${activeTab === "Post19" ? "active" : ""}`}
          onClick={() => handleTabClick("Post19")}
        >
          Post 19
        </button>
        <button
          className={`tab-button ${activeTab === "Post20" ? "active" : ""}`}
          onClick={() => handleTabClick("Post20")}
        >
          Post 20
        </button>
        <button
          className={`tab-button ${activeTab === "Post21" ? "active" : ""}`}
          onClick={() => handleTabClick("Post21")}
        >
          Post 21
        </button>
        <button
          className={`tab-button ${activeTab === "Post22" ? "active" : ""}`}
          onClick={() => handleTabClick("Post22")}
        >
          Post 22
        </button>
        <button
          className={`tab-button ${activeTab === "Post23" ? "active" : ""}`}
          onClick={() => handleTabClick("Post23")}
        >
          Post 23
        </button>
        <button
          className={`tab-button ${activeTab === "Post24" ? "active" : ""}`}
          onClick={() => handleTabClick("Post24")}
        >
          Post 24
        </button>
        <button
          className={`tab-button ${activeTab === "Post25" ? "active" : ""}`}
          onClick={() => handleTabClick("Post25")}
        >
          Post 25
        </button>
        <button
          className={`tab-button ${activeTab === "Post26" ? "active" : ""}`}
          onClick={() => handleTabClick("Post26")}
        >
          Post 26
        </button>
        <button
          className={`tab-button ${activeTab === "Post27" ? "active" : ""}`}
          onClick={() => handleTabClick("Post27")}
        >
          Post 27
        </button>
        <button
          className={`tab-button ${activeTab === "Post28" ? "active" : ""}`}
          onClick={() => handleTabClick("Post28")}
        >
          Post 28
        </button>
        <button
          className={`tab-button ${activeTab === "Post29" ? "active" : ""}`}
          onClick={() => handleTabClick("Post29")}
        >
          Post 29
        </button>
        <button
          className={`tab-button ${activeTab === "Post30" ? "active" : ""}`}
          onClick={() => handleTabClick("Post30")}
        >
          Post 30
        </button>
        <button
          className={`tab-button ${activeTab === "Post31" ? "active" : ""}`}
          onClick={() => handleTabClick("Post31")}
        >
          Post 31
        </button>
        <button
          className={`tab-button ${activeTab === "Post32" ? "active" : ""}`}
          onClick={() => handleTabClick("Post32")}
        >
          Post 32
        </button>
        <button
          className={`tab-button ${activeTab === "Post33" ? "active" : ""}`}
          onClick={() => handleTabClick("Post33")}
        >
          Post 33
        </button>
        <button
          className={`tab-button ${activeTab === "Post34" ? "active" : ""}`}
          onClick={() => handleTabClick("Post34")}
        >
          Post 34
        </button>
        <button
          className={`tab-button ${activeTab === "Post35" ? "active" : ""}`}
          onClick={() => handleTabClick("Post35")}
        >
          Post 35
        </button>
      </div>

      {/* Tab Content */}
      <div className="tabs-content">
        <div className="general-tab">

          {activeTab === "Post1" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p className="spacing-general-div">I was spending hours trying to keep track of all the leads and messages coming in from my Skool groups. I needed a way to stop missing opportunities and manage everything in one place, but the manual work was overwhelming.</p>
                <p className="spacing-general-div">Then, I found <strong>Teacher's Aid.</strong> It’s a powerful tool that makes managing Skool leads seamless!
                </p>
                <p className="spacing-general-div">Here’s what it does for me:
                </p>
                <ul className="spacing-general-div">
                  <li>Tracks every lead automatically from all my Skool groups.</li>
                  <li>Keeps all conversations organized in one place. </li>
                  <li>Integrates directly with my email marketing software and CRM.</li>
                  <li>Helps me close more deals with an intuitive pipeline.</li>
                  <li>Prevents me from ever losing a message or a lead again.
                  </li>
                </ul>
                <p className="spacing-general-div">And the best part? You can try it <strong>FREE</strong> before committing to anything!
                </p>
                <p className="spacing-general-div">Teacher’s Aid has completely transformed how I manage my Skool-based leads. I’m closing deals faster, following up with ease, and automating tasks I used to spend hours on. If you’re managing Skool groups and struggling to keep up, you NEED this tool.</p>
                <p>Start using <strong>Teacher's Aid</strong> today and reclaim your time! </p>
              </div>
            </div>
          </div>}
          {activeTab === "Post2" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p className="spacing-general-div">Let me save you some Skool frustration: if you’re running a Skool group for your business and you’re NOT using Teacher’s Aid, you’re working way harder than you need to.</p>
                <p>Here’s how I know:
                </p>
                <p className="spacing-general-div">I used to think Skool alone was enough to keep track of leads, follow-ups, and conversations. But as my community grew, I quickly realized I was losing track of everything.
                </p>
                <p className="spacing-general-div">Messages were piling up. Leads were falling through the cracks. And my revenue was stuck because I couldn’t keep up.
                </p>
                <p className="spacing-general-div">That’s when I found Teacher’s Aid.</p>
                <p>This Chrome Extension makes managing Skool effortless: </p>
                <p>✅ Keeps every lead, note, and message organized in one place.</p>
                <p>✅ Sends reminders so no follow-up ever gets missed.</p>
                <p className="spacing-general-div">✅ Helps you turn every message into a potential deal.</p>
                <p className="spacing-general-div">Now, instead of drowning in messages, I’m closing more deals and running my Skool group like a pro.
                </p>
                <p className="spacing-general-div">If you want to save yourself the headache (and start maximizing your Skool community), Teacher’s Aid is a no-brainer.</p>
                <p>Comment “SKOOL SMART” if you want to see how it works.</p>
              </div>
            </div>
          </div>}
          {activeTab === "Post3" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p>Why I’ll Never Manage My Skool Group Without This Tool</p>
                <p className="spacing-general-div">Running a Skool community is incredible… until you start drowning in messages, missed follow-ups, and disorganized leads.
                </p>
                <p className="spacing-general-div">I used to spend HOURS trying to keep track of who I talked to, what we discussed, and when to follow up. And even with all that effort, I still missed opportunities.
                </p>
                <p className="spacing-general-div">Then I found Teacher’s Aid.
                </p>
                <p>It’s like having a personal assistant built right into Skool. With this tool, I can:</p>
                <p>✅ Instantly see all my conversations and lead details in one place. </p>
                <p>✅ Set reminders for follow-ups (no more missed opportunities!).</p>
                <p className="spacing-general-div">✅ Focus on engaging my community instead of scrambling to keep up.</p>

                <p className="spacing-general-div">If you’re using Skool and want to streamline how you manage your leads and members, this is THE tool you need.
                </p>
                <p className="spacing-general-div">Drop a “💻” in the comments, and I’ll show you how it works.</p>
              </div>
            </div>
          </div>}
          {activeTab === "Post4" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p>The Secret Weapon for Skool Community Owners</p>
                <p className="spacing-general-div">If you’re using Skool to run your business, there’s one thing you can’t afford to ignore: FOLLOW-UPS.</p>
                <p>Here’s the harsh truth:</p>
                <p className="spacing-general-div">Every time you miss a follow-up, you’re leaving money (and trust) on the table.</p>
                <p className="spacing-general-div">I used to think I could keep track of all my leads and conversations inside Skool. Spoiler: I couldn’t.</p>
                <p className="spacing-general-div">That’s when I discovered Teacher’s Aid.
                </p>
                <p>This tool seamlessly integrates with Skool to help you:</p>
                <p>📋 Stay on top of every lead and conversation.</p>
                <p>🔔 Never forget to follow up with automated reminders.</p>
                <p className="spacing-general-div">📝 Keep notes on every interaction, so nothing falls through the cracks.</p>
                <p className="spacing-general-div">Since adding Teacher’s Aid to my Skool workflow, I’ve closed more deals, retained more clients, and created a better experience for my members.</p>
                <p className="spacing-general-div">If you’re running a Skool group and want to take it to the next level, you NEED this tool.</p>
                <p className="spacing-general-div">Comment “SKOOL SUCCESS” if you want to see how it works.</p>
              </div>
            </div>
          </div>}
          {activeTab === "Post5" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p>Running a Skool Group? Don’t Let Leads Slip Through the Cracks</p>
                <p className="spacing-general-div">Let me guess—you started your Skool community to build real connections, help people, and grow your business, right?</p>
                <p className="spacing-general-div">But as your community grows, keeping up with ALL the messages, follow-ups, and opportunities can feel like a full-time job.</p>
                <p className="spacing-general-div">I was in the same boat… until I found Teacher’s Aid.</p>
                <p>This Chrome Extension completely transformed how I manage my Skool group. Here’s what it does:</p>
                <p>✅ Organizes all your conversations and leads inside Skool.
                </p>
                <p>✅ Tracks who you’ve talked to and what you discussed.</p>
                <p className="spacing-general-div">✅ Sets follow-up reminders, so no message goes unanswered.</p>
                <p className="spacing-general-div">Since using Teacher’s Aid, I’ve gone from overwhelmed and scattered to organized and closing deals faster—and my community engagement has skyrocketed.</p>
                <p className="spacing-general-div">If you’re serious about running your Skool group like a pro and making it work for your business, you NEED this tool.</p>
                <p>Comment “SKOOL PRO” below, and I’ll show you how it works.</p>

              </div>
            </div>
          </div>}
          {activeTab === "Post6" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p>Here’s the truth about using Skool to grow your business:</p>
                <p className="spacing-general-div">If you don’t follow up, you’re leaving money on the table.</p>
                <p className="spacing-general-div">I learned this the hard way.</p>
                <p className="spacing-general-div">I thought I was staying on top of my leads, but between answering questions in my Skool group, managing conversations, and juggling follow-ups… things slipped through the cracks.</p>
                <p className="spacing-general-div">One day, I realized just how much I was missing out on. That’s when Teacher’s Aid came into my life.
                </p>
                <p>This Skool-integrated tool helped me:
                </p>
                <p>✅ Track every lead and conversation in one place.</p>
                <p>✅ Set reminders, so I never forget a follow-up.</p>
                <p className="spacing-general-div">✅ Close deals faster by staying 10 steps ahead.</p>
                <p className="spacing-general-div">Since then? No more missed messages, no more awkward follow-ups, and NO more lost revenue.</p>
                <p>If you’re ready to stop leaving Skool money on the table, comment “SKOOL” below, and I’ll show you how this works.</p>
              </div>
            </div>
          </div>}
          {activeTab === "Post7" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p className="spacing-general-div">You’ve Been Lied To About getting leads on Skool</p>
                <p>They told you keeping track of leads and messages on Skool is just part of the grind.</p>
                <p className="spacing-general-div">They told you that spreadsheets and manual processes are your only option.</p>
                <p>Here’s the truth:</p>
                <p>💥 Skool users don’t have to waste hours on admin tasks.</p>
                <p className="spacing-general-div">💥 You’re losing leads because your system is broken—not your effort.</p>
                <p className="spacing-general-div">Tools like <strong>Teacher’s Aid</strong> exist to automate this chaos. Imagine:</p>
                <ul className="spacing-general-div">
                  <li>Never losing another lead or message.</li>
                  <li>Having your pipeline auto-updated.</li>
                  <li>Closing deals faster with zero extra work.</li>
                </ul>
                <p>Stop believing the lie that it has to be hard. Try <strong>Teacher’s Aid</strong> for free and see how easy it can be: </p>
              </div>
            </div>
          </div>}
          {activeTab === "Post8" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p>Here’s the thing no one tells you about scaling your Skool community and business:</p>
                <p className="spacing-general-div">Leads don’t care how busy you are.</p>
                <p className="spacing-general-div">They want to feel like they’re your #1 priority, even when you’re juggling 10 other things.</p>
                <p className="spacing-general-div">I didn’t realize how much money I was leaving on the table until I started using Teacher’s Aid.</p>
                <p>This Skool integration does things I didn’t even know I needed:</p>
                <p>📋 Keeps all my leads in one place.</p>
                <p>🔔 Sends reminders, so I never forget a follow-up.</p>
                <p className="spacing-general-div">🗒️ Lets me take notes on every conversation (because details matter).</p>
                <p className="spacing-general-div">Now, instead of scrambling to remember what I said to someone last week, I can jump into conversations confidently—and close faster than ever before.</p>
                <p>Ready to make every lead count? Comment “YES” below, and I’ll send you a sneak peek of how it works.</p>
              </div>
            </div>
          </div>}
          {activeTab === "Post9" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <strong>FB Post 9</strong>
                <p className="spacing-general-div">The way you’re managing your Skool leads is outdated.</p>
                <p className="spacing-general-div">What are you doing?</p>
                <ul className="spacing-general-div">
                  <li>Copy-pasting member answers?</li>
                  <li>Manually adding names to spreadsheets?</li>
                  <li>Losing hours “organizing” instead of closing sales?</li>
                </ul>
                <p className="spacing-general-div">That’s not “working hard.” That’s wasting time.</p>
                <p className="spacing-general-div">Smart entrepreneurs know how to <i>delegate</i> to <i>automation</i>. Tools like <strong>Teacher’s Aid</strong> handle all the admin for you:</p>
                <ul className="spacing-general-div">
                  <li>Leads tracked ✅</li>
                  <li>Pipelines organized ✅</li>
                  <li>Deals closed faster ✅</li>
                </ul>
                <p>Your time is worth more. Start with Teacher’s Aid now: </p>
              </div>
            </div>
          </div>}
          {activeTab === "Post10" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p className="spacing-general-div">If your Skool group feels like chaos, you’re not alone.</p>
                <p className="spacing-general-div">Missed messages, forgotten follow-ups, and disorganized leads are killing your results.</p>
                <strong>Solution:</strong>
                <p className="spacing-general-div">That’s why I switched to <strong>Teacher’s Aid</strong></p>
                <p>This tool:</p>
                <p>✔️ Automatically tracks every lead.</p>
                <p>✔️ Organizes conversations into a clear pipeline.</p>
                <p className="spacing-general-div">✔️ Syncs with my email system to turn leads into customers.</p>
                <p className="spacing-general-div">Now I spend less time stressing and more time closing. </p>


                <p>Tired of the chaos? Let <strong>Teacher’s Aid</strong> fix it: </p>
              </div>
            </div>
          </div>}
          {activeTab === "Post11" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p className="spacing-general-div">Why You’re Losing Skool Leads</p>
                <p className="spacing-general-div">Do you know how many Skool leads you’re losing?</p>
                <p className="spacing-general-div">Most Skool users don’t.</p>
                <ul className="spacing-general-div">
                  <li>Messages get lost.</li>
                  <li>Leads forget you.</li>
                  <li>Follow-ups never happen.</li>
                </ul>
                <strong>Solution:</strong>
                <p className="spacing-general-div">With <strong>Teacher’s Aid</strong> that problem disappears.</p>
                <p>This tool:</p>
                <p>💡 Captures every lead for you.</p>
                <p>💡 Keeps all conversations in one place.</p>
                <p className="spacing-general-div">💡 Helps you close deals faster.</p>
                <p>Want a system that works FOR you? Start here: </p>
              </div>
            </div>
          </div>}
          {activeTab === "Post12" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p className="spacing-general-div">Feeling overwhelmed with Skool leads? The Answer to Your Skool Stress</p>
                <p className="spacing-general-div">It’s not you—it’s your process.</p>
                <p className="spacing-general-div">Spreadsheets and manual follow-ups are slowing you down.</p>
                <strong>Solution:</strong>
                <p className="spacing-general-div">Tools like <strong>Teacher’s Aid</strong> take the stress away:</p>
                <ul className="spacing-general-div">
                  <li>Leads tracked automatically.</li>
                  <li>Pipelines organized seamlessly.</li>
                  <li>Messages never lost.</li>
                </ul>
                <p>Reclaim your time and energy. Start using <strong>Teacher’s Aid</strong> today: </p>
              </div>
            </div>
          </div>}
          {activeTab === "Post13" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p className="spacing-general-div">Want to know the #1 tool successful Skool users rely on?</p>
                <p className="spacing-general-div">Here’s The Skool Secret the gurus don’t want you to know.</p>
                <p>It’s not spreadsheets.</p>
                <p>It’s not Zapier.</p>
                <p className="spacing-general-div">And it’s definitely not hours of manual work.</p>

                <p>It’s called <strong>Teacher’s Aid—</strong>and it automates the whole process:</p>
                <p>✔️ Tracks every lead. </p>
                <p>✔️ Keeps messages organized.</p>
                <p className="spacing-general-div">✔️ Turns chaos into clarity.</p>
                <p>Curious? Try it for free here:</p>
              </div>
            </div>
          </div>}
          {activeTab === "Post14" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p className="spacing-general-div">The gurus won’t like this, but someone needs to say it.</p>
                <strong>Body:</strong>
                <p>You’ve been told the key to growing your business is running “perfect ads.”</p>
                <p>Better targeting.</p>
                <p>More creative.</p>
                <p className="spacing-general-div">Catchier hooks.</p>

                <p className="spacing-general-div">That’s a LIE.</p>
                <p>The truth? </p>
                <p className="spacing-general-div">Your ads don’t matter if your backend systems are broken.</p>
                <ul className="spacing-general-div">
                  <li>Leads slip through the cracks.</li>
                  <li>Conversations get lost.</li>
                  <li>You waste time on admin work that could be automated.</li>
                </ul>
                <p className="spacing-general-div">It’s not your fault—you’ve been sold the wrong solution.</p>
                <p className="spacing-general-div">But here’s the fix: <strong>Teacher’s Aid.</strong> It tracks leads, organizes your pipeline, and automates your process so you can focus on <i>scaling</i>.</p>
                <p>Tired of guru advice that doesn’t work? Take control here: </p>
              </div>
            </div>
          </div>}
          {activeTab === "Post15" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p className="spacing-general-div">Most Skool users are sabotaging their own success.</p>
                <p>If your Skool group feels chaotic, there’s a reason:</p>
                <p className="spacing-general-div">You’re stuck in outdated systems.</p>
                <p>Spreadsheets? Manual follow-ups? Sound familiar?</p>
                <p className="spacing-general-div">These time-wasting strategies aren’t helping you scale.</p>

                <p>Smart entrepreneurs automate. They use tools like <strong>Teacher’s Aid</strong> to:</p>
                <p>✔️ Track every lead automatically. </p>
                <p>✔️ Keep pipelines clean and conversations organized.</p>
                <p className="spacing-general-div">✔️ Close more deals faster—with zero extra work.</p>
                <p>If you’re still doing it the hard way, it’s time for an upgrade.</p>
                <p>Click here to see how Teacher’s Aid works. </p>
              </div>
            </div>
          </div>}
          {activeTab === "Post16" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p className="spacing-general-div">Overwhelmed with Skool?</p>
                <p className="spacing-general-div">Managing Skool leads manually is exhausting.</p>
                <p className="spacing-general-div">You’re constantly:</p>
                <ul className="spacing-general-div">
                  <li>Copy-pasting member info.</li>
                  <li>Scrambling to follow up.</li>
                  <li>Missing out on opportunities.</li>
                </ul>
                <strong>Solution:</strong>

                <p className="spacing-general-div">That’s why I started using <strong>Teacher’s Aid.</strong></p>
                <p>It automates the chaos by: </p>
                <p>✔️ Tracking leads in real time.</p>
                <p>✔️ Keeping your pipeline organized.</p>
                <p className="spacing-general-div">✔️ Streamlining follow-ups so you don’t miss deals.</p>
                <p>If you’re tired of the overwhelm, it’s time for a change.</p>
                <p>Click here to simplify your process today.</p>
              </div>
            </div>
          </div>}
          {activeTab === "Post17" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p className="spacing-general-div">Your Skool inbox is chaos and you know it.</p>
                <p className="spacing-general-div">We’ve all been there.</p>
                <p className="spacing-general-div">A potential lead messages you on Skool… and a week later, you realize you forgot to follow up.</p>
                <p>Ouch.</p>
                <p className="spacing-general-div">With Teacher’s Aid, that NEVER has to happen again.</p>
                <p>Here’s how it works:</p>
                <p>📬 Keeps all your leads in one place, so you’re always organized.</p>
                <p>🔔 Sends you reminders to follow up at the right time.</p>
                <p className="spacing-general-div">📈 Tracks every step of your sales process with ease.</p>
                <p className="spacing-general-div">No more scrambling to remember who you need to contact.</p>
                <p className="spacing-general-div">If you’ve ever felt like you’re losing opportunities because of messy follow-ups, drop a ✋ in the comments. I’ll show you how Teacher’s Aid can help.</p>
              </div>
            </div>
          </div>}
          {activeTab === "Post18" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p className="spacing-general-div">What’s the Cost of a Missed Sale?</p>
                <p className="spacing-general-div">Every lead that slips through the cracks is a missed opportunity for your business.</p>
                <p className="spacing-general-div">That’s where <strong>Teacher’s Aid</strong> comes in.</p>
                <p className="spacing-general-div">It’s specifically designed for Skool users to:</p>
                <ul className="spacing-general-div">
                  <li><strong>Organize conversations</strong> and leads in one easy-to-use dashboard.</li>
                  <li><strong>Track every opportunity</strong> with reminders and notes.</li>
                  <li><strong>Close more deals</strong> by staying on top of your pipeline.</li>
                </ul>
                <p className="spacing-general-div">The result? Fewer missed messages. More sales. And a LOT less stress.</p>
                <p>Want to see how it works? Comment “INFO” below, and I’ll send you the details.</p>
              </div>
            </div>
          </div>}
          {activeTab === "Post19" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p className="spacing-general-div">Your Skool leads aren’t ghosting you because of your pitch.</p>
                <p className="spacing-general-div">They’re ghosting you because your system sucks. 😬 </p>
                <p>Messy spreadsheets.</p>
                <p>Missed follow-ups.</p>
                <p className="spacing-general-div">Lost conversations.</p>
                <p>The problem isn’t you—it’s how you’re managing your pipeline.</p>
                <p className="spacing-general-div">💡 Here’s the fix: Teacher’s Aid automates everything for you.</p>
                <p>✔️ Every lead tracked.</p>
                <p>✔️ Every message organized.</p>
                <p className="spacing-general-div">✔️ No more slipping through the cracks.</p>
                <p>Your leads deserve better—and so do you. Upgrade your system today. Start here:</p>
              </div>
            </div>
          </div>}
          {activeTab === "Post20" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p className="spacing-general-div">I used to think being “busy” on Skool meant I was doing well.</p>
                <p>But here’s the truth: </p>
                <p className="spacing-general-div">If you’re too busy to track your leads, you’re too busy to grow. </p>
                <p className="spacing-general-div">That hit me HARD one day when I missed out on a huge deal because I forgot to follow up.</p>
                <p>Now? I’ve got Teacher’s Aid doing the heavy lifting:</p>
                <p>✅ Every lead neatly organized in one dashboard.</p>
                <p>✅ Automated follow-up reminders, so no one gets left behind.</p>
                <p className="spacing-general-div">✅ Bulk messaging and notes to save me HOURS every week.</p>
                <p>The result?</p>
                <p className="spacing-general-div">I’ve closed more deals in the last month than I did in the previous three combined.</p>
                <p>If you’re ready to stop feeling overwhelmed and start seeing results, let’s talk. Comment below, and I’ll show you how this works.</p>
              </div>
            </div>
          </div>}
          {activeTab === "Post21" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p className="spacing-general-div">The Skool Wake-Up Call I Wish I Had Sooner</p>
                <p className="spacing-general-div">I used to think I could handle all my leads and conversations in Skool without missing a beat.</p>
                <p className="spacing-general-div">I mean, how hard could it be to track a few follow-ups, right?</p>
                <p>Turns out, I was <strong>DEAD wrong.</strong></p>
                <p className="spacing-general-div">One day, I had an amazing conversation with a lead. They were ready to move forward, excited about the possibilities. But when they messaged me a week later, I couldn’t remember half of what we talked about.</p>
                <p className="spacing-general-div">I fumbled through the chat, and you can probably guess the rest… they ghosted.</p>
                <p>That’s when I discovered Teacher’s Aid for Skool.</p>
                <p className="spacing-general-div">It organizes every conversation, tracks follow-ups, and even reminds me what to say next.</p>
                <p>The result?</p>
                <p>🔥 No more awkward “Wait, who is this?” moments.</p>
                <p>🔥 No more forgotten leads.</p>
                <p className="spacing-general-div">🔥 Just happy clients and sales flowing in.</p>
                <p>If you’re serious about growing your Skool community without losing opportunities, you NEED this. Drop a “🛠️” if you want the details.</p>
              </div>
            </div>
          </div>}
          {activeTab === "Post22" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p className="spacing-general-div">When it comes to choosing the right tools for my business, I’m picky. I want solutions that are simple, effective, and designed for my specific needs. That’s why, after trying a few clunky tools, I made the switch to <strong>Teacher’s Aid.</strong></p>
                <p className="spacing-general-div">Here’s why it stood out from the rest:</p>
                <ul className="spacing-general-div">
                  <li>It’s built specifically for Skool users, so I didn’t need complicated third-party integrations.</li>
                  <li>It organizes every lead and message automatically, saving me HOURS each week.</li>
                  <li>It’s affordable, easy to use, and even includes features I didn’t know I needed.</li>
                </ul>


                <p className="spacing-general-div">Other tools were either too expensive or didn’t work seamlessly with Skool. But with <strong>Teacher’s Aid,</strong> I’ve finally found a system that helps me grow my business without the headache.</p>
                <p className="spacing-general-div">Now my entire process runs on autopilot. I’m generating leads, tracking conversations, and closing sales faster than ever before—all with less effort.</p>
                <p className="spacing-general-div">The best part? You can test it out for FREE and see for yourself why so many Skool users swear by it.</p>
                <p>Ready to take control of your Skool leads? Start using <strong>Teacher’s Aid</strong> today:</p>
              </div>
            </div>
          </div>}
          {activeTab === "Post23" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p className="spacing-general-div">Skool changed how I connect with my audience.</p>
                <p>But here’s the thing:</p>
                <p className="spacing-general-div">If you’re not tracking your leads and follow-ups, you’re missing out on a TON of opportunities—no matter how amazing your community is.</p>
                <p className="spacing-general-div">I learned this the hard way when I lost out on a big deal because I forgot to follow up with someone who messaged me.</p>
                <p>That’s when I discovered <strong>Teacher’s Aid.</strong> It’s built right into Skool and has been a total game-changer:</p>
                <p>📋 Every lead is neatly organized in one place.</p>
                <p>🔔 Follow-up reminders, so no one gets left behind.</p>
                <p className="spacing-general-div">🗒️ Notes on every interaction, so I can pick up right where I left off.</p>
                <p className="spacing-general-div">Now, instead of feeling overwhelmed by Skool messages, I’m closing deals faster than ever before—and my group LOVES how responsive I am.</p>
                <p className="spacing-general-div">If you’re running a Skool group and want to take it to the next level, let’s chat. Drop a “🚀” in the comments, and I’ll show you what I’m using.</p>
              </div>
            </div>
          </div>}
          {activeTab === "Post24" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p className="spacing-general-div">Ever feel like managing leads from your Skool groups is eating up all your time? That was me not long ago. I was spending countless hours copy-pasting lead details, hunting through conversations, and trying not to lose track of potential clients.</p>
                <p className="spacing-general-div">One day, I had enough. That’s when I discovered <strong>Teacher’s Aid,</strong> and let me tell you—it’s a total lifesaver. </p>
                <p className="spacing-general-div">Here’s how it’s helped me:</p>
                <ul className="spacing-general-div">
                  <li>Every lead is automatically tracked and organized into a simple pipeline.</li>
                  <li>Messages are sorted so I never miss an important conversation again.</li>
                  <li>I can focus on closing deals and growing my business while <strong>Teacher’s Aid</strong> handles the busy work.</li>
                </ul>

                <p className="spacing-general-div">Since I started using it, my Skool group has gone from chaos to clarity. I’m closing more deals, spending less time on admin tasks, and my stress levels have dropped.</p>
                <p className="spacing-general-div">And guess what? You can try it for <strong>FREE</strong> to see how it works for you.</p>
                <p> Don’t let disorganization hold your business back. Give <strong>Teacher’s Aid</strong> a shot today:</p>

              </div>
            </div>
          </div>}
          {activeTab === "Post25" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p className="spacing-general-div">Managing leads and messages from my Skool groups used to feel like an impossible juggling act. I was spending hours every week sorting conversations, updating spreadsheets, and trying not to lose track of promising leads.</p>
                <p className="spacing-general-div">Then I found <strong>Teacher’s Aid,</strong> and everything changed. </p>
                <p className="spacing-general-div">With this simple, Skool-specific tool, I’ve:</p>
                <ul className="spacing-general-div">
                  <li>Automated my lead tracking—every new lead is logged instantly into a pipeline.</li>
                  <li>Organized conversations so I never miss important follow-ups.</li>
                  <li>Integrated with my email tools, turning leads into paying customers effortlessly.</li>
                </ul>
                <p className="spacing-general-div">Here’s the best part: I tested all these features for <strong>FREE</strong> before committing. In just a few weeks, I’ve saved hours of manual work and closed more deals than ever before.</p>
                <p>If you’re a course creator or group manager on Skool, you NEED this. Don’t let leads slip through the cracks like I used to. Start using <strong>Teacher’s Aid</strong> today and experience the difference for yourself! </p>
              </div>
            </div>
          </div>}
          {activeTab === "Post26" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p className="spacing-general-div">I’m someone who researches every tool before committing. If I’m going to invest in a system, it has to be the BEST on the market. That’s why I chose <strong>Teacher’s Aid</strong> to manage my Skool leads.</p>
                <p>Here’s what I discovered: </p>
                <p className="spacing-general-div">Many tools claim to automate lead tracking and organization, but most are overly complex and expensive. </p>
                <p>Some even require third-party integrations like Zapier, which only add more cost and hassle.</p>
                <p className="spacing-general-div">Then I found <strong>Teacher’s Aid</strong>, a simple, affordable solution designed specifically for Skool users.</p>
                <p className="spacing-general-div">With Teacher’s Aid, I can:</p>
                <ul className="spacing-general-div">
                  <li>Track and manage all my Skool leads effortlessly.</li>
                  <li>Automate lead organization without extra tools.</li>
                  <li>Keep every deal, lead, and message visible in one dashboard.</li>
                </ul>
                <p className="spacing-general-div">And the best part? You can test out all its features <strong>FREE</strong>  before making any commitments.</p>
                <p className="spacing-general-div">Now my entire lead tracking and messaging process is on autopilot. I’m saving time, closing more deals, and never letting a lead slip through the cracks.</p>
                <p className="spacing-general-div">If you’re managing leads on Skool, you NEED to try Teacher’s Aid. Here’s the link to get started:</p>
              </div>
            </div>
          </div>}
          {activeTab === "Post27" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p className="spacing-general-div">If you’re running a Skool group for your business, here’s a tough question:</p>
                <p className="spacing-general-div">How many leads, messages, or follow-ups have you forgotten about this week? </p>
                <p className="spacing-general-div">For me, the answer used to be “too many.” And it was costing me sales, opportunities, and trust with my members.</p>
                <p>That all changed when I started using Teacher’s Aid.</p>
                <p>This Chrome Extension for Skool is a game-changer:</p>
                <p>🔹 It organizes ALL your conversations in one place.</p>
                <p>🔹 Tracks your follow-ups, so no one gets left behind.</p>
                <p className="spacing-general-div">🔹 Boosts your response time and community engagement.</p>
                <p className="spacing-general-div">With Teacher’s Aid, I’ve turned my Skool community into a well-oiled machine that doesn’t just connect people—it closes deals.</p>

                <p>Curious? Comment “SKOOL AID” below, and I’ll give you the details.</p>
              </div>
            </div>
          </div>}
          {activeTab === "Post28" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p className="spacing-general-div">Let’s face it—managing a growing Skool community can be overwhelming.</p>
                <p className="spacing-general-div">It’s not just about creating great content or answering questions. It’s about keeping up with all the conversations, follow-ups, and potential clients reaching out. </p>
                <p>Before I found Teacher’s Aid, I was constantly playing catch-up.</p>
                <p className="spacing-general-div">Now? My process is streamlined, and I’m closing deals faster than ever.</p>
                <p>Here’s how Teacher’s Aid helps:</p>
                <p>✅ Keeps all your Skool conversations in one place.</p>
                <p>✅ Tracks who you need to follow up with and when.</p>
                <p className="spacing-general-div">✅ Gives you an edge by never letting a lead slip through the cracks.</p>
                <p className="spacing-general-div">If you’re using Skool for your business, this is the tool you didn’t know you needed.</p>
                <p>Drop a “🚀” in the comments, and I’ll show you how it works.</p>
              </div>
            </div>
          </div>}
          {activeTab === "Post29" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p className="spacing-general-div">When I first started using Skool, I thought I had it all figured out.</p>
                <p className="spacing-general-div">But as my community grew, so did the chaos. Messages were piling up, follow-ups were forgotten, and I was leaving deals on the table. </p>
                <p className="spacing-general-div">That’s when I discovered Teacher’s Aid.</p>
                <p>This tool changed EVERYTHING. It integrates with Skool to:</p>
                <p>🔹 Track every lead and conversation automatically.</p>
                <p>🔹 Set follow-up reminders, so I never miss an opportunity.</p>
                <p className="spacing-general-div">🔹 Keep my community running smoothly without the overwhelm.</p>
                <p className="spacing-general-div">Now, my Skool group isn’t just active—it’s profitable.</p>
                <p className="spacing-general-div">If you’re serious about taking your Skool community to the next level, Teacher’s Aid is a no-brainer.</p>
                <p>Comment “NEXT LEVEL” to learn more.</p>
              </div>
            </div>
          </div>}
          {activeTab === "Post30" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p className="spacing-general-div">Running a Skool group is amazing… until you realize how hard it is to keep up with all the messages, leads, and follow-ups.</p>
                <p className="spacing-general-div">If you’ve ever felt like you’re drowning in your Skool inbox, I get it. I’ve been there.</p>
                <p>The good news? Teacher’s Aid makes it EASY.</p>
                <p>Here’s what it does:</p>
                <p>✅ Organizes all your Skool conversations in one simple dashboard.</p>
                <p>✅ Tracks follow-ups, so no opportunity slips away.</p>
                <p className="spacing-general-div">✅ Helps you close more deals without working 24/7.</p>
                <p className="spacing-general-div">If you’re tired of feeling overwhelmed by Skool, this tool will be a lifesaver.</p>
                <p>Drop a “SKOOL TOOLS” in the comments, and I’ll show you how it works.</p>
              </div>
            </div>
          </div>}
          {activeTab === "Post31" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p>Don’t Let Skool Opportunities Slip Away</p>
                <p className="spacing-general-div">Know that sinking feeling when you realize you forgot to follow up with a Skool lead?</p>
                <p className="spacing-general-div">It used to happen to me ALL the time—until I found Teacher’s Aid.</p>
                <p>This tool is a game-changer for anyone using Skool to run their business. It helps you:</p>
                <p>🔹 Stay organized with all your conversations.</p>
                <p>🔹 Follow up with every lead (without the stress).</p>
                <p className="spacing-general-div">🔹 Turn more Skool messages into paying clients.</p>
                <p className="spacing-general-div">If you’re serious about growing your Skool group and managing it like a pro, Teacher’s Aid is the answer.</p>
                <p>Comment “SKOOL PRO” if you want to see how it works.</p>
              </div>
            </div>
          </div>}
          {activeTab === "Post32" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p className="spacing-general-div">Skool Groups Made Simple</p>
                <p className="spacing-general-div">Managing a Skool community can feel like a juggling act. One missed message, and you’ve lost a lead.</p>
                <p>That’s why I swear by <strong>Teacher’s Aid</strong>.</p>
                <p className="spacing-general-div">It’s the ultimate tool for Skool users who want to stay organized and close more deals.</p>
                <p>Here’s how it helps:</p>
                <p>✅ Automatically tracks your leads and conversations in Skool.</p>
                <p>✅ Sets reminders, so you NEVER forget a follow-up.</p>
                <p className="spacing-general-div">✅ Keeps your business running smoothly, even as your community grows.</p>
                <p>If you’re ready to take your Skool group to the next level, comment “SKOOL SUCCESS,” and I’ll share the details.</p>
              </div>
            </div>
          </div>}
          {activeTab === "Post33" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p>The Best Tool for Skool Business Owners</p>
                <p className="spacing-general-div">If you’re using Skool to grow your business, there’s ONE tool you can’t afford to skip: Teacher’s Aid.</p>
                <p>This Chrome Extension is designed specifically for Skool users who want to:</p>
                <p>🔹 Organize all their messages and leads in one place.</p>
                <p>🔹 Track follow-ups with ease.</p>
                <p className="spacing-general-div">🔹 Close more deals without letting anything fall through the cracks.</p>
                <p className="spacing-general-div">Since I started using Teacher’s Aid, managing my Skool group has gone from stressful to seamless—and my revenue has followed.</p>
                <p>Want to know more? Drop a “📈” in the comments, and I’ll show you how it works.</p>
              </div>
            </div>
          </div>}
          {activeTab === "Post34" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p>The Day I Stopped Leaving Skool Money on the Table</p>
                <p className="spacing-general-div">If you’re running a Skool group for your business, let me ask you this:</p>
                <p className="spacing-general-div">How many opportunities have you missed simply because you forgot to follow up?</p>
                <p className="spacing-general-div">I used to think my memory was good enough to track it all. Spoiler: It wasn’t.</p>
                <p className="spacing-general-div">The truth? Every missed follow-up was money left on the table.</p>
                <p className="spacing-general-div">That all changed when I found Teacher’s Aid. This Chrome Extension doesn’t just help you manage your Skool community—it turns it into a revenue machine.</p>
                <p>Here’s how:</p>
                <p>✅ Never miss a follow-up: Get automatic reminders for every conversation.</p>
                <p>✅ All your leads in one place: Organized, easy to access, and impossible to forget.</p>
                <p className="spacing-general-div">✅ Faster deal flow: Spend less time chasing and more time closing.</p>
                <p className="spacing-general-div">Since using Teacher’s Aid, my Skool group feels like a well-oiled machine—and my sales reflect that.</p>
                <p className="spacing-general-div">If you’re tired of losing track of leads and missing out on opportunities, Teacher’s Aid is the tool you’ve been waiting for.</p>
                <p>Drop a “💰” in the comments if you’re ready to stop leaving money on the table.</p>
              </div>
            </div>
          </div>}
          {activeTab === "Post35" && <div className="tab-content">
            <div className="scroller-general-div">
              <div className="general-tab-block">
                <p className="spacing-general-div">I’ve been running my Skool groups for a while now, and honestly? It used to feel like chaos trying to keep up with all the messages, leads, and tasks. I was juggling multiple spreadsheets and even lost track of important messages a few times.</p>
                <p className="spacing-general-div">Then, a fellow Skool user recommended <strong>Teacher’s Aid,</strong> and WOW—it has been a game-changer!</p>
                <p className="spacing-general-div">Here’s what makes it so amazing:</p>
                <ul className="spacing-general-div">
                  <li>Automatically captures every lead and organizes it in a pipeline.</li>
                  <li>Keeps my messages sorted, so I never miss an opportunity.</li>
                  <li>Works seamlessly with email marketing tools to close more sales.</li>
                </ul>
                <p className="spacing-general-div">With Teacher’s Aid, my lead generation and follow-up process runs on autopilot. I’ve already seen a huge boost in my productivity—and my revenue!</p>
                <p className="spacing-general-div">The best part? You can start for <strong>FREE</strong> and explore all the features yourself.</p>
                <p>Stop struggling to manage leads manually—give <strong>Teacher’s Aid</strong>a try today!</p>
              </div>
            </div>
          </div>}
          <div className="copy-btn">
            <button onClick={copyToClipboard}>
            <LuCopy /> Copy
            </button>
          </div>

          {/* <div className="view-more-btn">
            <button>
              View more  <FaAngleDoubleDown />
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

