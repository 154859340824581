import React from 'react';
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import blueclock from "../../assets/images/blue-clock.svg";
import deleteicon from "../../assets/images/trash.svg"
import editicon from "../../assets/images/edit-colorful.svg"


export default function Draftreminder() {
    return (
        <div className="modal-overlay editor_modal-default">
            <div className="modal-content model_reminder" >
                <div className="modal-body" >
                    <div className='modal-heading-draft'>
                        <div className='content-heading'>
                            <img src={blueclock} alt="clock" />
                            <h6>Reminder</h6>
                        </div>
                        <div className='button-box'>
                            <button>
                                <img src={deleteicon} alt="clock" />
                            </button>
                            <button>
                                <img src={editicon} alt="clock" />
                            </button>
                        </div>
                    </div>
                    <div className='model_flex_box'>
                        <div className='textarea-box'>
                            <textarea
                                id="note"
                                placeholder="Alice has some issues setting up her thing and she needs me to assist her in 3 days."
                                rows={3}
                            />
                            <label htmlFor="note">Remind me in 2 Months</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}