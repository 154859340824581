import React, { useEffect, useRef, useState } from 'react';
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";

export default function Remindermodel({ show, setShow }) {
    return (
        <div className="modal-overlay editor_modal-default">
            <div className="modal-content model_reminder" >
                <div className="modal-body" >
                    <div className='modal-heading'>
                        <h6>Reminder</h6>
                    </div>
                    <div className='model_flex_box'>
                        <div className='textarea-box'>
                            <label htmlFor="note">Note</label>
                            <textarea
                                id="note"
                                placeholder="Enter your note here"
                                rows={3}
                            />
    
                        </div>
                        {/* {errors.note && <p className="error">{errors.note}</p>} */}
                        <div className="reminder-options">
                            <label htmlFor="remind-in">Remind me in</label>
                            <div className="remind-in">
                                <div className='input_position'>
                                    <input
                                        type="number"
                                        min="1"
                                    />
                                    <ul>
                                        <li style={{ cursor: "pointer" }}>
                                            <FaPlus />
                                        </li>
                                        <li style={{ cursor: "pointer" }}>
                                            <FaMinus />
                                        </li>
                                    </ul>
                                </div>
                                <select>
                                    <option>Months</option>
                                    <option>Weeks</option>
                                    <option>Days</option>


                                    <option>Hours</option>
                                    <option>Minutes</option>
                                </select>
                            </div>
                            {/* {errors.remindIn && <p className="error">{errors.remindIn}</p>} */}

                        </div>
                        <button className='save-button'>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}