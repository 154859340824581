import { FaArrowRightLong } from "react-icons/fa6";

export default function Affilateearnsection(){
    return(
        <div>
            <div className="earn_section start-earning">
        <div className="row">
          <div className="col-md-6 my-auto d-lg-block d-none">
            {/* <img src="/images/earn-image.png" alt="" /> */}
          </div>
          <div className="col-lg-6 my-auto center_box">
            <div className="contant_column">
              <h2>Ready to Start Earning?</h2>
              <p className="pt-3 pb-5">Join the Teacher’s Aid Affiliate Program today and start sharing a tool that makes life easier for thousands of users.</p>
              <div className="btn_earn">
                <button onClick={() => window.open(`https://affiliates.lemonsqueezy.com/programs/teachersaid`, '_blank')}>
                Sign up for free
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
    )
}