/** @format */

// CSS/Sass files
import './App.css';
import './Global.scss';
import './App.scss';
import './Extensionmodel.scss';

import React, { useEffect, useState } from 'react';
import { Routes, Route, useParams, useLocation, useNavigate } from 'react-router-dom';
import Unauthorized from './components/Unauthorized';

import HomePage from "./pages/HomePage.jsx"
import Login from './pages/auth/Login.js';
import Signup from './pages/auth/Signup';
import ProtectedRoute from './components/ProtectedRoute.js';
import Page404 from './screens/Page404.jsx';
import axios from 'axios';
import Header from './components/Header.jsx';
import Footer from './components/Footer.jsx';
import Forgotpassword from './pages/auth/Forgotpassword.js';
import Resetpassword from './pages/auth/Resetpassword.js';
import Mailcheck from './components/Mailcheck.jsx';
import Alldone from './components/Done.jsx';
import Pricingpage from './pages/Pricingpage.jsx';
import Support from "./pages/Support.jsx"
import { authenticateAsyncThunk } from './redux/pagesSlices/authSlice.js';
import { useDispatch } from 'react-redux';
import Feedback from './pages/Feedback.js';
import Welcomescreen from './pages/Welcomescreen.jsx';
import Refundpolicy from './pages/Refundpolicy.jsx';
import Privatepolicy from './pages/Privatepolicy.jsx';
import Extensionpath from './pages/Extensionmodels/Extensionpath.jsx';
import Mybookmarks from './pages/Extensionmodels/Mybookmarks.jsx';
import Draftmodel from './pages/Extensionmodels/Draft-model.jsx';
import Bookmarksaved from './pages/Extensionmodels/Bookmarksaved.jsx';
import Affilateprogram from './pages/Affilateprogram.jsx';

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const user = localStorage.getItem("user");
  console.log("🚀 ~ HomePage ~ user:", user);

  useEffect(() => {
    dispatch(authenticateAsyncThunk());
  }, []);

  // useEffect(() => {
  //   if (!user) {
  //     navigate("/login"); // Redirects to the login page
  //   }
  // }, [user, navigate]);


  // let user = JSON.parse(localStorage.getItem('user'));

  // useEffect(() => {


  //   const fetchData = async () => {
  //     try {
  //       const ipResponse = await axios.get('https://api.ipify.org?format=json');
  //       const ipData = ipResponse.data;
  //       const zipCode = await axios.get(`http://ip-api.com/json/${ipData?.ip}`);
  //       console.log('zipCode>>>>>>>>>', zipCode);
  //       // localStorage.setItem("userIp",ipData?.ip);
  //       if (!user || user == null) {
  //         localStorage.setItem('userIp', ipData?.ip);
  //         localStorage.setItem('userZipCode', zipCode?.data?.zip);
  //       } else {
  //         localStorage.removeItem('userIp');
  //         // localStorage.removeItem("userZipCode");
  //         localStorage.setItem('userZipCode', zipCode?.data?.zip);
  //       }
  //     } catch (error) {
  //       console.error('Error:', error);
  //     }
  //   };
  //   fetchData();
  // }, []);

  // const location = useLocation();
  // console.log('🚀 ~ App ~ location:', location);

  // const shouldShowSidebar =
  //   location.pathname == '/login' || location.pathname == '/signup';

  // const [userData, setUserData] = useState(null);

  // useEffect(() => {
  //   const fetchUserData = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_contentBasePath}auth/current_user`,
  //         {
  //           withCredentials: true, // Ensure cookies are sent with the request
  //         }
  //       );
  //       console.log('🚀 ~ fetchUserData ~ response:', response);
  //       {
  //         response?.data?.data &&
  //           localStorage.setItem(
  //             'user',
  //             JSON.stringify(response?.data?.data?.user)
  //           );
  //       }
  //       {
  //         response?.data?.data?.tokens &&
  //           localStorage.setItem(
  //             'app-access-token',
  //             JSON.stringify(response?.data?.data?.tokens?.access?.token)
  //           );
  //       }
  //       {
  //         response?.data?.data?.tokens &&
  //           localStorage.setItem(
  //             'app-refresh-token',
  //             JSON.stringify(response?.data?.data?.tokens?.refresh?.token)
  //           );
  //       }
  //       setUserData(response.data.data);
  //     } catch (error) {
  //       console.error('Error fetching user data:', error);
  //     }
  //   };

  //   fetchUserData();
  // }, []);

  return (
    <>
      <div className='App' style={{overflowX:"hidden"}}>
        {/* <Header/> */}
        <Routes>
          {/* auth routes */}
          <Route path='/signup' element={<Signup />} />
          <Route path='/login' element={<Login />} />
          <Route path='/forgot-password' element={<Forgotpassword />} />
          <Route path='/reset-password' element={<Resetpassword />} />
          <Route path='/mail-check' element={<Mailcheck />} />
          <Route path='/all-done' element={<Alldone />} />
          <Route path='/feedback' element={<Feedback />} />
          <Route
            path='/'
            element={
              <HomePage />
            }
          />

          <Route
            path='/pricing'
            element={
              <Pricingpage />
            }
          />
          <Route
            path='/support'
            element={
              <Support />
            }
          />
          <Route
            path='/training'
            element={
              <Support />
            }
          />
          <Route
            path='/welcome'
            element={
              <Welcomescreen />
            }
          />
          <Route
            path='/refund'
            element={
              <Refundpolicy />
            }
          />
           <Route
            path='/policy'
            element={
              <Privatepolicy />
            }
          />
          <Route
            path='/extension-path'
            element={
              <Extensionpath />
            }
          />
          <Route
            path='/bookmark-path'
            element={
              <Mybookmarks />
            }
          />
          <Route
            path='/draft-model'
            element={
              <Draftmodel />
            }
          />
          <Route
            path='/bookmark-saved'
            element={
              <Bookmarksaved />
            }
          />
          <Route
            path='/affiliate-program'
            element={
              <Affilateprogram />
            }
          />
          {/* <Route path='/unauthorized' element={<Unauthorized />} /> */}
          {/* <Route path='*' element={<Page404 />} /> */}
        </Routes>
        {/* <Footer/> */}
      </div>
    </>
  );
}

export default App;
