import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import bookmarkimg from "../../assets/images/bookmark.svg";
import bookmarksaved from "../../assets/images/bookmark-saved.svg"

export default function Bookmarksaved() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div className='model-save-main-div'>
            <Button variant="primary" onClick={handleShow}>
                Launch demo modal
            </Button>

            <Modal show={show} onHide={handleClose} centered className='bookmark-save-model'>
                <Modal.Header closeButton>
                    <Modal.Title><img src={bookmarkimg} alt="bookmark" /> Bookmark saved</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='model-extension-save-body'>
                        <div>
                            <img src={bookmarksaved} alt="" />
                        </div>
                        <button className='close-btn' onClick={handleClose}>Close</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}